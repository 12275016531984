import { Product } from '@model/product';
import { Variant } from '@model/product/VariantsInfo';
import { clothesSizeToNumber } from '@utils/product/clothesSizeToNumber';

export const initiallySelectedVariant = (product: Product) => {
  const variantsBySize = variantsOrderBySizeDescending(product.variantInfo?.variants);
  return variantsBySize?.find((variant) => product.id === variant.productId) ?? variantsBySize?.[0];
};
const variantsOrderBySizeDescending = (variants?: Variant[]) => variants?.sort((a, b) => clothesSizeToNumber(a.size) - clothesSizeToNumber(b.size)).reverse() ?? []


