import { useInitialData } from '@context/initialDataContext'
import { useSpecialPrice } from '@hooks/product'
import { useCurrency } from '@hooks/useCurrency'
import { usePriceInfo } from '@hooks/usePriceInfo'
import { useTranslate } from '@hooks/useTranslation'
import type { Promotion } from '@model/misc/Promotion'
import type { Product } from '@model/product'
import { getPriceValueWithCurrency } from '@utils/currency'
import { getPriceByWeight } from '@utils/price'
import { getBasePrice } from '@utils/product/getBasePrice'
import { getAllProductsQuantity } from '@utils/product/getQuantities'
import { PERCENTAGE_OF_POINT_USED } from '../constants/product'
import { minimumValue } from '@utils/product/old-price-utils'

export const usePriceCalculator = (
  enhancedProduct: Product,
  promotionInfo: Promotion | undefined,
  allQuantities: any
) => {
  const { currency } = useCurrency()
  const {
    initialData: { currencies, allPromotions },
  } = useInitialData()
  const { translate } = useTranslate()

  const priceInfo = usePriceInfo(enhancedProduct, allPromotions)
  const { normalPrice, topPrice, normalPriceEur } = priceInfo
  const discountedPrice = normalPrice > topPrice ? topPrice : 0
  const priceByWeight = getPriceByWeight(translate)(enhancedProduct.model, topPrice, currency)

  let { promoPrice } = enhancedProduct
  if (promoPrice) {
    promoPrice = getPriceValueWithCurrency(promoPrice, currency, currencies)
  }

  let specialPrice = useSpecialPrice(enhancedProduct) ? promoPrice || priceInfo.specialPrice : 0
  const specialPriceEur = useSpecialPrice(enhancedProduct) ? priceInfo.promoPriceEur || priceInfo.specialPriceEur : 0
  if (discountedPrice && !specialPrice) specialPrice = discountedPrice

  const allProductsQuantity = getAllProductsQuantity(priceInfo, allQuantities)
  const basePrice = getBasePrice(priceInfo, allQuantities, specialPrice)

  const promotionPrice = !promotionInfo ? 0 : Number(promotionInfo.custom2) > allQuantities ? normalPrice : specialPrice

  const promotionPriceEur = !promotionInfo
    ? 0
    : Number(promotionInfo.custom2) > allQuantities
    ? normalPriceEur
    : specialPriceEur

  const totalPriceBeforeCo = (promotionPrice || basePrice) * allProductsQuantity

  const totalPriceForCalculateCo =
    minimumValue([promotionPriceEur, specialPriceEur, normalPriceEur, priceInfo.promoPriceEur]) * allProductsQuantity
  const totalPriceAfterCo =
    totalPriceBeforeCo - (totalPriceBeforeCo * parseFloat(enhancedProduct[PERCENTAGE_OF_POINT_USED] || '0')) / 100

  return {
    priceByWeight,
    specialPrice,
    totalPriceBeforeCo,
    basePrice,
    totalPriceAfterCo,
    totalPriceForCalculateCo,
  }
}
