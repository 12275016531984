import { AppRoutes } from '@app/routes';
import { Variant } from '@model/product/VariantsInfo';
import { getSlug } from '@utils/slugs';
import router from 'next/router';

export const getHandleSelectVariant = (variant: Variant, catalogId: string | string[]) => {
  const urlSegments = variant.url.split('-')
  let variantSku = urlSegments[urlSegments.length - 1]

  if (urlSegments.includes('W') && variant.url.includes('W-')) {
    variantSku = `W-${variantSku}`
  } else if (urlSegments.includes('L') && variant.url.includes('L-')) {
    variantSku = `L-${variantSku}`
  }
  const name = variant.name.toLowerCase().split(' ').join('-')
  const productSlug = getSlug(name, variantSku)

  const href = catalogId
    ? `/${catalogId}${AppRoutes.productDetails.get(productSlug)}`
    : AppRoutes.productDetails.get(productSlug)

  router
    .replace(href, undefined, {
      scroll: false,
    })
    .catch(console.error)
};
