import { Product } from '@model/product';
import { PriceInfo } from '@model/product/PriceInfo';

export const getProductIdOfBundle = (product: Product, priceInfo: PriceInfo, sku: string) => {
  const bundleProduct = getBundleProduct(priceInfo, sku)
  return  bundleProduct ? bundleProduct.id : product.id
}

export const getBundleProduct = (priceInfo: PriceInfo, sku: string) => {
  return (priceInfo.bundleIncludedProducts || []).find(({ sku: bSku }) => bSku === sku)
}
