import { useCallback, useEffect, useState } from 'react'
import type { CartItem, ItemToOperate, UpdateBasketItem } from '@model/cart/CartItem'
import useSWR from 'swr'
import { useRouter } from 'next/router'
import type { Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import * as R from 'ramda'
import { cartItemsAdder, cartItemsFetcher, cartItemsRemover, cartItemsUpdater } from '@api/shoppingCartFetchers'
import { useAlert } from '@context/alert'
import { useTranslate } from '@hooks/useTranslation'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'
import axios from 'axios'

const countTotalFromCart = R.compose(R.sum, R.map<CartItem, number>(R.propOr<number>(0, 'finalPriceExTax')))

export interface CartHook {
  cart: CartItem[]
  cartError: any
  isLoading: boolean
  addToCart(item: ItemToOperate): Promise<void>
  addToCartWithSideEffects(item: ItemToOperate, CB?: () => void): Promise<void>
  updateBasketItem(item: UpdateBasketItem): Promise<void>
  removeItem(item: UpdateBasketItem): Promise<void>
  itemsCount: number
  cartTotal: number
  clearBasket: () => Promise<void>
}

export type CartType = 'shopping list' | 'shopping cart'

const fetchers = {
  'shopping list': cartItemsFetcher,
  'shopping cart': cartItemsFetcher,
} as const

const adders = {
  'shopping list': cartItemsAdder,
  'shopping cart': cartItemsAdder,
} as const

const updaters = {
  'shopping list': cartItemsUpdater,
  'shopping cart': cartItemsUpdater,
} as const

const removers = {
  'shopping list': cartItemsRemover,
  'shopping cart': cartItemsRemover,
} as const

const countCartItems = R.compose(R.sum, R.map<CartItem, number>(R.propOr(0, 'quantity')))

export const useCart: (key: CartType) => CartHook = (key) => {
  const [count, setCount] = useState(0)
  const { locale } = useRouter()
  const languageId = KK_LANG_IDS[locale as Locale]
  const handleRouteChange = () => {
    const event = new Event('cws-header-activate')

    dispatchEvent(event)
  }
  const {
    data: cart,
    error,
    mutate,
    isValidating,
  } = useSWR(isGloballyPricelist() ? null : key, fetchers[key](languageId), {
    revalidateOnFocus: false,
    refreshWhenHidden: false,
  })

  const addToCart = useCallback(
    async (item: ItemToOperate & { custom3?: string }) => {
      item.custom3 = `${languageId}:${locale}`
      await adders[key](languageId, item)()
      await mutate(async (data: CartItem[]) => [...data, item], true)
    },
    [locale, languageId, mutate, key]
  )

  const addToCartSafe = useCallback(
    async (item: ItemToOperate) => {
      try {
        await adders[key](languageId, item)()
        await mutate(async (data: CartItem[]) => [...data, item], true)
      } catch (e) {
        console.error(`Cannot add product ${item.sku} = `, (e as Error).message)
      }
    },
    [languageId, mutate, key]
  )

  const { showAlert } = useAlert()

  const { translate } = useTranslate()

  const addToCartWithSideEffects = useCallback(
    (item: ItemToOperate, CB?: () => void) => {
      return addToCart(item)
        .then(() => {
          CB && CB()
          showAlert({ text: translate('basket.items.added') })
        })
        .catch(() => {
          showAlert({ text: translate('basket.items.add.error.general'), error: true })
        })
    },
    [addToCart, showAlert, translate]
  )

  const updateBasketItem = useCallback(
    async (item: UpdateBasketItem) => {
      try {
        await updaters[key](languageId, item)()
        await mutate(async (data: CartItem[]) => [...data, item], true)
      } catch (e) {
        console.warn(`Cannot update cart for product item ${item.sku} = `, (e as Error).message)
      }
    },
    [key, languageId, mutate]
  )

  const removeItem = useCallback(
    async (item: UpdateBasketItem) => {
      try {
        await removers[key](languageId, item)()
        await mutate(async (data: CartItem[]) => data, true)

        if (cart.length === 1) {
          handleRouteChange()
        }
      } catch (e) {
        console.warn(`Cannot remove product ${item.sku} = `, (e as Error).message)
      }
    },
    [key, languageId, mutate, cart]
  )
  const clearBasket = useCallback(async () => {
    if (!cart) return
    try {
      await axios.post('/api/cart/clear', {
        languageId,
        items: cart.map((item: CartItem) => ({
          sku: item.product.sku,
          productId: item.productId,
          id: item.id,
          customerId: 0,
          quantity: 0,
        })),
      })
      await mutate(async (data: CartItem[]) => data, true)
      window.sessionStorage.removeItem('currentOrderHash')
    } catch (e) {
      console.warn(`Cannot remove products`)
    }
  }, [languageId, cart])

  useEffect(() => {
    if (cart && !isValidating) {
      setCount(() => countCartItems(cart))
    }
  }, [cart, isValidating])

  return {
    cart,
    cartError: error,
    addToCart,
    addToCartWithSideEffects,
    updateBasketItem,
    removeItem,
    isLoading: isValidating,
    itemsCount: count,
    cartTotal: countTotalFromCart(cart || []),
    clearBasket,
  }
}
