import '@style/main.scss'
import '@style/sso.scss'
import 'cms-web-styleguide/dist/assets/styles/cws.min.css'
import { BASE_API } from '@api/baseApi'
import { getCampaignPeriodContents } from '@api/content'
import { getBranchNameWhenDevelopmentEnv } from '@api/getBranchNameWhenDevelopmentEnv'
import { getTranslation } from '@api/messages'
import { getPreloadedInitialData } from '@api/serverSideInitialData'
import { getPreloadedSubsetTranslations } from '@api/serverSideTranslations'
import { MOBILE_BP } from '@app/constants'
import AxiosConfig from '@components/AxiosConfig'
import { Layout } from '@components/common'
import { AppAlert } from '@components/shared/AppAlert'
import { AppInfo } from '@components/shared/AppInfo'
import { CampaignPeriod } from '@components/shared/CampaignPeriod'
import type { AlertContextProps, AlertOptions } from '@context/alert'
import { AlertContext } from '@context/alert'
import { InitialDataProvider } from '@context/initialDataContext'
import { PreloadedInitialDataProvider } from '@context/PreloadedInitialDataContext'
import { PreloadedTranslationsProvider } from '@context/PreloadedTranslationsContext'
import { TranslationsProvider } from '@context/TranslationsContext'
import type { CCLocale, Locale } from '@model/locales'
import { CC_LOCALE, COUNTRY_CODES, KK_LANG_IDS, KK_LOCALE_MAP } from '@model/locales'
import { useMediaLess } from '@ui/hooks/useMediaLess'
import logger from '@utils/logger'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'
import { handleRedirects } from '@utils/redirects'
import axios from 'axios'
import type { AppContext, AppProps } from 'next/app'
import App from 'next/app'
import getConfig from 'next/config'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { BareFetcher, SWRConfiguration } from 'swr'
import { SWRConfig } from 'swr'
import type { GlobalEnv } from '../additional'
import AppStateProvider from '../context/state'
import { getPlatinumLoungeStory } from '@utils/getPlatinumLoungeStory'

const reCaptchaSiteKey = getConfig().publicRuntimeConfig.reCaptchaSiteKey

const swrFetcher: BareFetcher<any> = (resource: any) => {
  return axios.post(resource).then((res) => res.data)
}

const swrConfig: SWRConfiguration = {
  fetcher: swrFetcher,
}

export default function W2App({
  Component,
  pageProps,
  engineHost,
  konakartServer,
  ssoServer,
  flags,
  serverSideDictionary,
  serverSideInitialData,
  cwsWebSrc,
  ssoScript,
  isPricelist,
  isPlatinumLounge,
  developBranchName,
  GTM_ID,
  title,
  campaignPeriod,
}: AppProps & GlobalEnv) {
  const LayoutComponent = (Component as any).Layout || Layout
  const router = useRouter()
  const isMobile = useMediaLess(MOBILE_BP)
  const { query } = router
  const isClientSide = typeof window !== 'undefined'
  const prevLocale = useRef(router.locale)

  if (isClientSide) {
    window.digiData = {
      website: {},
      environment: 'webshop',
      type: isMobile ? 'mobile' : 'desktop',
      currency: 'eur',
      locale: router.locale as Locale,
      routeCode: 'shop',
      country: COUNTRY_CODES[router.locale as Locale],
    }

    const isPlatinumLoungeStory = getPlatinumLoungeStory(query)

    if (!window.envs) {
      window.envs = {
        engineHost,
        konakartServer,
        ssoServer,
        serverSideDictionary,
        serverSideInitialData,
        flags,
        cwsWebSrc,
        ssoScript,
        isPricelist,
        developBranchName,
        isPlatinumLounge,
        GTM_ID,
      }
    }

    if (window.envs && prevLocale.current !== router.locale && serverSideInitialData) {
      window.envs = { ...window.envs, serverSideInitialData }
      prevLocale.current = router.locale
    }

    if (window.envs && isPlatinumLoungeStory && 'catalogId' in query && query.catalogId === 'mystar') {
      window.envs.isPlatinumLounge = true
    } else if (window.envs) {
      window.envs.isPlatinumLounge = false
    }
  }

  useEffect(() => {
    const onCmsWebLoaded = function () {
      const locale: CCLocale = CC_LOCALE[router.locale as Locale]
      window?.CWC?.render({
        env: 'e-store',
        link: false,
        locale,
      })
    }
    window.addEventListener('cms-web-consent-root-loaded', onCmsWebLoaded)
    return () => {
      window.removeEventListener('cms-web-consent-root-loaded', onCmsWebLoaded)
    }
  }, [router.locale])

  useEffect(() => {
    const handleRouteChange = () => {
      const event = new Event('cws-header-activate')

      dispatchEvent(event)
    }
    router.events.on('hashChangeComplete', handleRouteChange)
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('hashChangeComplete', handleRouteChange)
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  const [alertToShow, setAlertToShow] = useState<AlertOptions | null>(null)
  const [alertTO, setAlertTO] = useState<ReturnType<typeof setTimeout> | null>(null)

  const isIndexPage = router.pathname === '/'

  const showAlert = useCallback((alertOptions: AlertOptions) => {
    setAlertToShow(alertOptions)
    setAlertTO(
      setTimeout(() => {
        setAlertToShow(null)
      }, alertOptions.hideDelay ?? 3000)
    )
  }, [])

  const hideAlert = useCallback(() => {
    setAlertToShow(null)

    if (alertTO) {
      clearTimeout(alertTO)
    }
    setAlertTO(null)
  }, [alertTO])

  const alertContextValue = useMemo<AlertContextProps>(() => {
    return { alertOptions: alertToShow, showAlert, hideAlert }
  }, [alertToShow, showAlert, hideAlert])

  if (typeof window === 'undefined') {
    logger.info(`Rendering page: ${Component.name}`)
  }

  if (router.pathname.includes('klarna')) {
    return <Component {...pageProps} />
  }

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0" />
        <meta name="google" content="notranslate" />
      </Head>
      {/* Google Tag Manager  */}
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push( {'gtm.start': new Date().getTime(),event:'gtm.js'}
            );var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://analytics.tallink.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_ID}');
          `,
        }}
      />
      {/* End Google Tag Manager */}

      {ssoScript && <Script id="sso-script" src={ssoScript} strategy="afterInteractive" />}
      {cwsWebSrc && <Script id="cms-web" src={cwsWebSrc} async />}
      {/*<Script strategy="afterInteractive" src={`https://www.google.com/recaptcha/api.js`} />*/}
      <Script strategy="afterInteractive" src={`https://www.google.com/recaptcha/api.js?render=${reCaptchaSiteKey}`} />

      <AxiosConfig>
        <SWRConfig value={swrConfig}>
          <PreloadedTranslationsProvider
            serverSideTranslations={isClientSide ? window.envs.serverSideDictionary : serverSideDictionary}
          >
            <PreloadedInitialDataProvider
              serverSideInitialData={isClientSide ? window.envs.serverSideInitialData : serverSideInitialData}
            >
              <TranslationsProvider>
                <AppStateProvider>
                  <InitialDataProvider>
                    <AlertContext.Provider value={alertContextValue}>
                      <LayoutComponent>
                        {!isIndexPage && Boolean(campaignPeriod) && (
                          <CampaignPeriod variant="info" info={campaignPeriod as string} />
                        )}
                        {!isGloballyPricelist() && <AppInfo />}
                        <div className="z-[10] fixed top-40 inset-x-0 max-w-5xl w-full mx-auto">
                          <AppAlert options={alertToShow} onHideAlert={hideAlert} />
                        </div>
                        <Component {...pageProps} />
                      </LayoutComponent>
                    </AlertContext.Provider>
                  </InitialDataProvider>
                </AppStateProvider>
              </TranslationsProvider>
            </PreloadedInitialDataProvider>
          </PreloadedTranslationsProvider>
        </SWRConfig>
      </AxiosConfig>
    </>
  )
}

const PRICELIST_STORE = 'store1'

W2App.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  const {
    ctx: { req, locale, query },
  } = appContext

  handleRedirects(appContext.ctx)

  const isBrowser = typeof req === 'undefined'

  if (!isBrowser) {
    const isPlatinumLounge = 'platinum-lounge' in query && 'catalogId' in query && query.catalogId === 'mystar'
    const ssoServer = process.env.SSO_SERVER
    const ssoScript = process.env.SSO_SCRIPT
    const GTM_ID = process.env.GOOGLE_TAG_MANAGER_ID
    const cwsWebSrc = process.env.CWS_WEB_SCRIPT
    const storeId = process.env.KK_STORE
    const enableBrands = process.env.ENABLE_BRANDS && JSON.parse(process.env.ENABLE_BRANDS)
    const disableRecaptcha = process.env.RECAPTHA_DISABLE && JSON.parse(process.env.RECAPTHA_DISABLE)
    const isPricelist = storeId === PRICELIST_STORE

    const title = await getTranslation(
      KK_LOCALE_MAP[locale as Locale],
      isPricelist ? 'w2.pric.title.main' : 'w2.shop.title.main'
    )

    const campaignPeriod = isPricelist ? await BASE_API.getCampaignPeriodContents(KK_LANG_IDS[locale as Locale]) : ''

    // logger.debug('[App]', {
    //   pathname: appContext.ctx.pathname,
    // })

    // cliLogger.debug(`[App] working with pathname '${appContext.ctx.pathname}'`)

    const developBranchName = await BASE_API.getBranchNameWhenDevelopmentEnv()

    /* Do not add anything of heavy resource here */
    /* Keep it as small as possible - given data is for react SSR */
    const serverSideDictionary = await BASE_API.getPreloadedSubsetTranslations(KK_LOCALE_MAP[locale as Locale])

    const serverSideInitialData = await BASE_API.getPreloadedInitialData(
      KK_LANG_IDS[locale as Locale],
      locale as string
    )
    const serverSideInitialDataAggregated = {
      ...serverSideInitialData,
      isPricelist,
    }

    return {
      ...appProps,
      developBranchName,
      konakartServer: process.env.KONAKART_HOST,
      engineHost: process.env.KK_HOST,
      ssoServer,
      ssoScript,
      cwsWebSrc: `${cwsWebSrc}?t=${Date.now()}`,
      isPricelist,
      isPlatinumLounge,
      GTM_ID,
      serverSideDictionary,
      serverSideInitialData: serverSideInitialDataAggregated,
      flags: {
        enableBrands,
        disableRecaptcha,
      },
      title,
      campaignPeriod,
    }
  }

  return appProps
}
