import { PriceInfo } from '@model/product/PriceInfo';
import * as R from 'ramda';

/* Refactor this - essentially the same code copied from the same file with small differences */
export const getAllQuantities = (itemsQuantityState: any, bundleTypeTab: string, prodSku: string) => {
  const quantityList = Object.keys(itemsQuantityState)
    .filter((sku) => isSingleSKu(sku, bundleTypeTab, prodSku))
    .map((sku) => itemsQuantityState[sku])

  return R.sum(quantityList)
};

export const getAllPriceSum = (itemsQuantityAndPrices: any, bundleTypeTab: string, prodSku: string) => {
  if (!itemsQuantityAndPrices) return 0

  const quantityList = Object.keys(itemsQuantityAndPrices)
    .filter((sku) => isSingleSKu(sku, bundleTypeTab, prodSku))
    .map((sku) => itemsQuantityAndPrices[sku][1])
  return R.sum(quantityList)
};

export const getPricesSum = (itemsQuantityAndPrices: any, bundleTypeTab: string, prodSku: string) => {
  const quantityList = Object.keys(itemsQuantityAndPrices)
    .filter((sku) => bundleTypeTab === 'single' ? sku === prodSku : true)
    .map((sku) => itemsQuantityAndPrices[sku][1])
  return R.sum(quantityList)
};

export const getPriceOnOneProducts = (itemsQuantityAndPrices: any, bundleTypeTab: string, prodSku: string) => {
  return Object.keys(itemsQuantityAndPrices)
    .filter((sku) => (bundleTypeTab === 'single' ? sku === prodSku : itemsQuantityAndPrices[sku][1]))
    .map((sku) => itemsQuantityAndPrices[sku][1] / itemsQuantityAndPrices[sku][0])
    .sort((a, b) => a - b)
};

export const getSkus = (itemsQuantityState: any, bundleTypeTab: string, productSku: string) => {
  return Object.keys(itemsQuantityState).filter((sku) =>
    bundleTypeTab === 'single' ? sku === productSku : itemsQuantityState[sku],
  )
}

export const isSingleSKu = (sku: string, bundleTypeTab: string, prodSku: string) =>
  bundleTypeTab === 'single' ? sku === prodSku : true

export const getAllProductsQuantity = (priceInfo: PriceInfo, allQuantities: number) => {
  const { bundleQuantity, bundleFreeProducts } = priceInfo

  if (!bundleQuantity) return allQuantities

  const freeProductMultiplier = Math.floor(allQuantities / bundleQuantity)
  const freeProducts = freeProductMultiplier * bundleFreeProducts

  return allQuantities - freeProducts
}

export const getCartItemsQuantity = (cart: any) => {
  return cart?.reduce(
    (acc: any, val: any) => ({
      ...acc,
      [val.sku]: val.quantity,
    }),
    {} as Record<string, number>,
  )
}
