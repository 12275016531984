import { CwsAlert, CwsGridCol, CwsGridRow, CwsH5, CwsInput, CwsP, CwsSelect, CwsSelectOption } from '@components/cws'
import type { FC, ChangeEvent } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslate } from '@hooks/useTranslation'
import { useCartState } from '@components/cart/State/state'
import useSWR from 'swr'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
import type { PickupPoint } from '@api/ecw/models/PickupPoint'
import AddressInputWithSearch from '@components/cart/delivery/DeliveryMethods/AddressInputWithSearch'
import CourierList from '@components/cart/delivery/DeliveryMethods/CourierList'
import type { KonakartCarrierCode } from '@model/order/ShippingQuote'
import type { DeliveryMethod, DeliveryMethodWithQuote } from '@api/ecw/models/DeliveryMethod'
import HomeDelivery from '@components/cart/delivery/DeliveryMain/HomeDelivery'
import type { OptionsSelect } from '@ui/components/SelectSearch/SelectSearch'
import type { PickupPointRequest } from '@api/ecw/models/PickupPoint'
import { COUNTRY_ISO2 } from '@model/locales'
import { validations } from '@utils/order'
import { localStorageHelper } from '@utils/localStorageHelper'
import { alphabetOption, sortStringsByDetectedLanguage } from '@utils/sortStringsByDetectedLanguage'

export const emptyPickUpPoint = {
  name: '',
  address1: '',
  id: '0',
}

interface DeliverySubtypeProps {
  codesMap: Record<KonakartCarrierCode, DeliveryMethodWithQuote>
  deliveryMethods?: DeliveryMethod[]
  fromReturn?: boolean
}

export const DeliverySubtype: FC<DeliverySubtypeProps> = ({ codesMap, deliveryMethods, fromReturn }) => {
  const { translate } = useTranslate()
  const {
    dispatch,
    state: { deliveryMethodsByCountry, deliveryCountry, selectedPickUpPointId, order, homeDeliveryAddress },
  } = useCartState()
  const hasAlcohol = useMemo(() => !!order?.orderProducts.find((item) => item.custom2), [order])

  const selectPickUpPoint = useCallback(
    (val: string) => dispatch({ type: 'selectPickUpPointId', payload: val }),
    [dispatch]
  )

  const selectedDeliveryMethod = useMemo(
    () => deliveryMethodsByCountry[deliveryCountry],
    [deliveryMethodsByCountry, deliveryCountry]
  )

  const [zip, setZip] = useState<string>('')

  useEffect(() => {
    setZip('')
    setAddressString('')
  }, [deliveryCountry, setZip])

  const shouldShowAndCheckZipCode = useMemo(
    () =>
      (deliveryCountry === 'Finland' || deliveryCountry === 'Sweden') &&
      !selectedDeliveryMethod?.express &&
      selectedDeliveryMethod?.parcelMachine,
    [deliveryCountry, selectedDeliveryMethod]
  )

  const { data: response, isValidating } = useSWR<AxiosResponse<PickupPoint[]>>(
    deliveryMethodsByCountry[deliveryCountry] &&
      `puPoints-${deliveryMethodsByCountry[deliveryCountry]?.konakartCarrierCode}-${zip}`,
    () =>
      axios.post<PickupPointRequest, AxiosResponse<PickupPoint[]>>(`/api/pickup-points`, {
        carrierCode: deliveryMethodsByCountry[deliveryCountry]?.konakartCarrierCode,
        noCache: true,
        hasAlcohol: hasAlcohol,
        countryCode: COUNTRY_ISO2[deliveryCountry],
        ...(zip && { zip }),
      } as PickupPointRequest)
  )
  const zipListTallinkTakso = useMemo(() => {
    if (deliveryMethodsByCountry[deliveryCountry]?.konakartCarrierCode === 'EKSPRESS' && response?.data) {
      return response?.data.map((item) => item.zipCode)
    }
    return []
  }, [response?.data, deliveryMethodsByCountry[deliveryCountry]?.konakartCarrierCode])

  useEffect(() => {
    if (response && response?.data?.length) {
      const deliveryPoint = response.data.find((ps) => ps.id === selectedPickUpPointId)
      dispatch({ type: 'setSelectDeliveryPoint', payload: deliveryPoint || null })
    }
  }, [selectedPickUpPointId, response, dispatch])

  const showHomeDeliveryForm = useMemo(() => {
    if (selectedDeliveryMethod?.konakartCarrierCode === 'BUDBEESPFI') return true
    const isTaxi = selectedDeliveryMethod?.konakartCarrierCode === 'EKSPRESS'
    return !selectedDeliveryMethod?.parcelMachine && !selectedDeliveryMethod?.express
  }, [selectedDeliveryMethod])

  const optionsPickUpPoint: OptionsSelect[] = useMemo(() => {
    if (!response?.data) return []
    const res = response.data.map<OptionsSelect>((item) => ({
      id: item.id,
      name: `${item.name || ''} (${item.address1})`,
    }))
    if (alphabetOption[deliveryCountry]) return sortStringsByDetectedLanguage(res, alphabetOption[deliveryCountry])
    return res
  }, [response?.data, deliveryCountry])
  const parcelMachineLabel = useMemo(() => {
    if (selectedDeliveryMethod?.express) return translate('w2.cart.parcel.pickup.point')
    return translate('basket.courier.selection.machine.filter.label')
  }, [translate, selectedDeliveryMethod])

  const [addressString, setAddressString] = useState<string>('')
  const zipHistory = localStorageHelper.get('zipValue')
  useEffect(() => {
    if (homeDeliveryAddress?.streetAddress && homeDeliveryAddress.city && homeDeliveryAddress.zip) {
      setAddressString(`${homeDeliveryAddress.streetAddress},${homeDeliveryAddress.city}`)
      setZip(homeDeliveryAddress.zip)
      return
    }
    if (zipHistory) {
      setZip(zipHistory)
    }
  }, [])

  // useEffect(() => {
  //   if (shouldShowAndCheckZipCode && deliveryCountry === 'Sweden' && !addressString) setZip('')
  // }, [addressString, deliveryCountry, shouldShowAndCheckZipCode])

  const zipPattern = validations.zip
  if (!deliveryMethodsByCountry[deliveryCountry] || !response) return null

  const handleChangePickUpPoint = (value: string) => {
    selectPickUpPoint(value)
  }

  return (
    <>
      <CwsGridRow noGutter>
        <CwsH5 className="cws-py-12-lg" weight="500">
          {showHomeDeliveryForm ? translate('basket.shipping.address.header') : parcelMachineLabel}
        </CwsH5>
      </CwsGridRow>
      <CwsGridRow noGutter className="w-full">
        <CwsGridCol col="12">
          {/*{deliveryCountry === 'Sweden' && <pre>{zip}</pre>}*/}
          {/*{deliveryCountry === 'Sweden' && <pre>{addressString}</pre>}*/}
          {shouldShowAndCheckZipCode && deliveryCountry === 'Sweden' && (
            <AddressInputWithSearch
              zipValue={zip}
              onZipChange={setZip}
              addressString={addressString}
              setAddressString={setAddressString}
            />
          )}

          {shouldShowAndCheckZipCode && deliveryCountry !== 'Sweden' && (
            <CwsGridRow noGutter className="w-full">
              <CwsGridCol col="6">
                <CwsInput
                  id="check-delivery-zip-filter"
                  name="zip"
                  label={translate('basket.shipping.address.zip')}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const zipValue = e.target.value
                    setZip(e.target.value)
                    localStorageHelper.set('zipValue', zipValue)
                    if (zipValue.match(zipPattern)) {
                    }
                  }}
                  value={zip}
                  invalid={!zip.match(zipPattern)}
                />
                <CwsAlert variant="inline" className="cws-justify-content-center" type="info">
                  <CwsP size="small">{translate('basket.courier.zip.help')}</CwsP>
                </CwsAlert>
                <div className="flex flex-row min-h-[2px] min-w-[35px] lg:min-w-[106px] lg:mb-4 mx-3 lg:mx-2"></div>
              </CwsGridCol>
            </CwsGridRow>
          )}
          <CourierList
            codesMap={codesMap}
            deliveryMethodsForReturnOrder={deliveryMethods}
            disabled={shouldShowAndCheckZipCode && !Boolean(zip)}
          />
          {selectedDeliveryMethod?.parcelMachine && (
            <CwsSelect
              placeholder={
                selectedDeliveryMethod.express
                  ? translate('w2.basket.express.pick.terminal')
                  : translate('basket.pick.terminal')
              }
              onChange={handleChangePickUpPoint}
              value={selectedPickUpPointId}
              disabled={
                !Boolean(optionsPickUpPoint.length) || (shouldShowAndCheckZipCode ? !Boolean(zip) : isValidating)
              }
            >
              {optionsPickUpPoint.map(({ id, name }) => (
                <CwsSelectOption key={id} title={name} value={id} />
              ))}
            </CwsSelect>
          )}
          {showHomeDeliveryForm && (
            <HomeDelivery
              fromReturn={fromReturn}
              withoutZip={selectedDeliveryMethod?.konakartCarrierCode === 'BUDBEESPFI'}
            />
          )}
          {selectedDeliveryMethod?.konakartCarrierCode === 'EKSPRESS' && (
            <HomeDelivery zipListTallinkTakso={zipListTallinkTakso} fromReturn={fromReturn} />
          )}
        </CwsGridCol>
      </CwsGridRow>
    </>
  )
}
