import type { Product } from '@model/product';

export function getProductTitle(product: Product): string {
  const { name, manufacturerName, custom3 } = product

  const base = name.replace(manufacturerName, '')
  const alcoholVol = custom3 ? ` ${custom3}% vol` : ''

  return `${base}${alcoholVol}`
}
