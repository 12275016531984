import { sessionStorageHelper } from '@utils/localStorageHelper'
import type { ParsedUrlQuery } from 'querystring'

export const getPlatinumLoungeStory = (query: ParsedUrlQuery) => {
  const expirationTime = Date.now() + 2 * 60 * 60 * 1000
  const platinumLounge = sessionStorageHelper.get('platinum-lounge')

  if (platinumLounge && Date.now() > platinumLounge?.expires) {
    sessionStorageHelper.remove('platinum-lounge')
    return false
  }

  if (platinumLounge && platinumLounge?.value) {
    return true
  }

  if (!platinumLounge) {
    const isPlatinumLounge = 'platinum-lounge' in query && 'catalogId' in query && query.catalogId === 'mystar'
    if (isPlatinumLounge) {
      sessionStorageHelper.set('platinum-lounge', { value: isPlatinumLounge, expires: expirationTime })
      platinumLounge.value = true
      return true
    }
  }

  return false
}
