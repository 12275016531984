import type { Promotion } from '@model/misc/Promotion';
import type { Product } from '@model/product';
import { getCommonGaDto } from '@utils/getCommonGaDto';

export const buildGaEventDto = (product: Product, allPromotions: Promotion[], normalPrice: number, eventName: any, currency: any, basePrice: any, quantity: any, translate: any) => {
  const { bundleLabel = '' } = product.priceInfo ?? { bundleLabel: '' }
  const commonDto = getCommonGaDto(product, allPromotions, bundleLabel, translate)
  const productUrlArr = product.url.split('/')

  const category = productUrlArr[1]
  const category2 = productUrlArr[2]
  const discount = normalPrice - basePrice
  const item = {
    ...commonDto,
    price: normalPrice.toString(),
    discount: discount ? discount.toFixed(2).toString() : '',
    currency: currency,
    item_brand: product.manufacturerName,
    item_category: category ?? '',
    item_category2: category2 ?? '',
    quantity: quantity?.toString(),
    ...(eventName === 'add_to_cart' ? { index: 1, item_list_id: 'detail' } : {}),
  }

  return { item }
}
