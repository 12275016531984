import { useInitialData } from '@context/initialDataContext';
import { usePriceInfo } from '@hooks/usePriceInfo';
import { Product } from '@model/product';

export const useDiscountValue = (enhancedProduct: Product, discount: number | undefined, allQuantities: any) => {
  const {
    initialData: { allPromotions },
  } = useInitialData()

  const priceInfo = usePriceInfo(enhancedProduct, allPromotions)

  if (priceInfo.bundleType && priceInfo.bundleSavePercent && allQuantities >= priceInfo.bundleQuantity) {
    return `-${priceInfo.bundleSavePercent}%`;
  }
  if (discount) {
    return `-${discount}%`;
  }
  return priceInfo.youSavePercent ? `-${priceInfo.youSavePercent}%` : '';
}
