import { CwsAlert, CwsH4, CwsP } from '@components/cws';
import { Mail } from '@components/product/ProductViewParameters/assets';
import { OutOfStock } from '@components/product/ProductViewParameters/OutOfStock';
import moduleStyles from '@components/product/ProductViewParameters/ProductViewParameters.module.scss';
import { useTranslate } from '@hooks/useTranslation';
import type { Product } from '@model/product';
import { CustomAttrArray } from '@model/product';
import { Button } from '@ui/components';
import React, { type FC } from 'react';

interface ComponentProps {
  isOpenCheckEmail: boolean;
  product: Product;
  setIsOpenCheckEmail: any
}

export const ProductViewParametersIsOutOfStock: FC<ComponentProps> = ({
                                                                  isOpenCheckEmail, product, setIsOpenCheckEmail,
                                                                }) => {
  const { translate } = useTranslate()

  return (
    <>
      {isOpenCheckEmail ? (
        <OutOfStock productId={product.id} />
      ) : (
        <>
          <div
            className="
                         flex flex-row items-center space-x-2
                         text-midnight font-normal justify-center text-sm mb-lg
                         "
          >
            <CwsAlert variant="inline" type="info">
              <CwsP size="small">{translate('product.detail.product.disabled')}</CwsP>
            </CwsAlert>
          </div>
          <Button
            variant="outline"
            size="sm"
            className="flex flex-row items-center justify-center"
            onClick={() => setIsOpenCheckEmail(true)}
          >
            <Mail />
            <span className="ml-2.5 pt-[1px]">{translate('product.details.subscribe.to.back.in.stock')}</span>
          </Button>
        </>
      )}
    </>
  )
}
