import { getPromotionsTag } from '@components/product/ProductCard/ProductTagsRow/tags-utils';
import type { Promotion } from '@model/misc/Promotion';
import type { Product } from '@model/product';
import { getBadge } from '@utils/getBadge';
import { getCustomAttrCustomFacetCampaign, getCustomAttrCustomFacetLabel } from '@utils/getCustomAttrCustomFacetCampaign';

export const getCommonGaDto = (product: Product, allPromotions: Promotion[], bundleLabel: string | null, translate: any) => {
  const campaign = getCustomAttrCustomFacetCampaign(product.customAttrArray)
  const label = getCustomAttrCustomFacetLabel(product.customAttrArray)
  const { promotionName } = getPromotionsTag(allPromotions)(product.promotionResults)
  const badge = getBadge(promotionName, campaign, bundleLabel, label)

  return {
    item_name: product.name,
    item_id: product.sku,
    item_brand: product.manufacturerName,
    promotion_name: translate(badge),
  }
}
