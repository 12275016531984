import { useAlert } from '@context/alert';
import { useInitialData } from '@context/initialDataContext';
import { useCart } from '@hooks/useCart';
import { usePriceInfo } from '@hooks/usePriceInfo';
import { useTranslate } from '@hooks/useTranslation';
import { Product } from '@model/product';
import { getBundleProduct } from '@utils/product/getProductIdOfBundle';
import { getSkus } from '@utils/product/getQuantities';

export const useHandleAddToCart = (
  itemsQuantityState: any,
  product: Product,
  cartItemsQuantity: any,
  bundleTypeTab: string,
  gaEvent: any,
  maxQuantity: number
) => {
  const {
    initialData: { allPromotions },
  } = useInitialData();
  const priceInfo = usePriceInfo(product, allPromotions);
  const { translate } = useTranslate();
  const { showAlert } = useAlert();
  const { addToCartWithSideEffects } = useCart('shopping cart');

  const handleAddToCart = async () => {
    const cartItemQuantity = itemsQuantityState[product.sku];
    const currentCartItemQuantity = cartItemsQuantity[product.sku];
    const skus = getSkus(itemsQuantityState, bundleTypeTab, product.sku);

    try {
      if (priceInfo.bundleIncludedProducts?.length) {
        for (const sku of skus) {
          if (cartItemsQuantity[sku] + itemsQuantityState[sku] > maxQuantity) {
            return showAlert({ text: translate('basket.items.add.error.general'), error: true });
          }

          const bundleProduct = getBundleProduct(priceInfo, sku)

          if (bundleProduct) {
            try {
              await addToCartWithSideEffects(
                {
                  productId: bundleProduct.id,
                  sku: bundleProduct.sku,
                  quantity: itemsQuantityState[sku],
                },
                () => {
                  gaEvent(product, itemsQuantityState[sku], 'add_to_cart');
                }
              );
            } catch (e) {
              console.error(`Error adding ${sku} to cart`, e);
            }
          }
        }
      } else {
        if (currentCartItemQuantity + cartItemQuantity > maxQuantity) {
          return showAlert({ text: translate('basket.items.add.error.general'), error: true });
        }
        await addToCartWithSideEffects(
          { productId: product.id, sku: product.sku, quantity: cartItemQuantity },
          () => {
            gaEvent(product, cartItemQuantity, 'add_to_cart');
          }
        );
      }
    } catch (e) {
      console.error(`Error adding ${product.sku} to cart`, e);
    }
  };

  return { handleAddToCart };
};
