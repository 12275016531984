import { CustomAttrArray } from '@model/product';
import { CustomFacetNumber } from '@model/product/CustomFacet';

/* Could be moved to hook. Returning both campaign and label*/
export const getCustomAttrCustomFacetCampaign = (customAttrArray: CustomAttrArray[]) => {
  return (customAttrArray || []).filter((attr) => attr.facetNumber === CustomFacetNumber.Campaign).map(e => e.value)
}

export const getCustomAttrCustomFacetLabel = (customAttrArray: CustomAttrArray[]) => {
  return (customAttrArray || []).filter((attr) => attr.facetNumber === CustomFacetNumber.Label).map(e => e.value)
}
