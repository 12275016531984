import { UserData } from '@lib/gtm';
import { Customer } from '@model/customer/Customer';

export const getCustomerUserData = (customer: Customer | null): UserData | null  => {
  const user = customer
    ? {
      user_email: customer.emailAddr,
      user_phone: customer.telephoneNumber,
      user_id: customer.id,
    }
    : null

  return user;
}
