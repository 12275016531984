import type { FC } from 'react'
import {
  CwsGrid,
  CwsGridCol,
  CwsGridRow,
  CwsH3,
  CwsSpan,
  CwsP,
  CwsButton,
  CwsDivider,
  CwsSection,
  CwsList,
  CwsListItem,
  CwsA,
  CwsInput,
  CwsImg,
  CwsForm,
} from '@components/cws'
// @ts-ignore
import { modal } from 'cms-web-styleguide/dist/utils/utils'
import { ChangeEvent, useState } from 'react'
import { useTranslate } from '@hooks/useTranslation'
import { FormSubscription } from '@components/common/Subscription/FormSubscription'
import { useRouter } from 'next/dist/client/router'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'
type LinkJoinClubOne = { clubOneLink: string; oneMoreLink: string }
const linkJoinClubOne = (locale: string | undefined): LinkJoinClubOne => {
  switch (locale) {
    case 'sv':
      return { clubOneLink: 'https://se.tallink.com/bli-medlem', oneMoreLink: 'https://se.tallink.com/club-one' }
    case 'fi':
      return { clubOneLink: 'https://fi.tallink.com/liity-jaseneksi', oneMoreLink: 'https://fi.tallink.com/club-one' }
    case 'en':
      return {
        clubOneLink: 'https://en.tallink.com/en/join-club-one',
        oneMoreLink: 'https://en.tallink.com/en/club-one',
      }
    case 'lv':
      return {
        clubOneLink: 'https://lv.tallink.com/lv/joining',
        oneMoreLink: 'https://lv.tallink.com/lv/club-one-programme',
      }
    case 'lt':
      return {
        clubOneLink: 'https://lv.tallink.com/lt/joining',
        oneMoreLink: 'https://lv.tallink.com/lt/club-one-programme',
      }
    default:
      return {
        clubOneLink: `https://ee.tallink.com/${locale ?? 'et'}/club-one-liitumisankeet`,
        oneMoreLink: `https://ee.tallink.com/${locale ?? 'et'}/club-one`,
      }
  }
}

const Subscription: FC = () => {
  const [emailValue, setEmailValue] = useState('')
  const [isVisibleFormModal, setIsVisibleFormModal] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const { locale } = useRouter()
  const { translate } = useTranslate()

  const handleChangeEmailValue = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value)
    setIsInvalid(false)
  }
  const changeVisibleModal = () => {
    if (/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(emailValue)) {
      modal.addEventModalShow('form-modal')
      setIsVisibleFormModal(true)
    } else {
      setIsInvalid(true)
    }
  }

  const { clubOneLink, oneMoreLink } = linkJoinClubOne(locale)

  const copyright = translate('w2.copyright.message', {
    privacyPolicy: `<a class="cws-a cws-a--theme-white cws-a--size-tiny cws-a--inline cws-a--color-dark-grey" rel="noopener noreferrer" target="_blank" href="https://policies.google.com/privacy?hl=${locale}">${translate(
      'footer.privacy.policy'
    )}</a>`,
    termsOfService: `<a class="cws-a cws-a--theme-white cws-a--size-tiny cws-a--inline cws-a--color-dark-grey" rel="noopener noreferrer" target="_blank" href="https://policies.google.com/terms?hl=${locale}">${translate(
      'w2.terms.of.service'
    )}</a>`,
  })
  return (
    <CwsSection className="cws-py-40 cws-py-60-sm" data-analytics-group="Membership" id="club-one">
      <CwsGrid width="fluid">
        <FormSubscription
          visible={isVisibleFormModal}
          setVisible={setIsVisibleFormModal}
          setEmailValue={setEmailValue}
          emailAddress={emailValue}
        />
        <CwsGridRow noGutter>
          <CwsGridCol
            className="cws-bb cws-b-color-medium-grey cws-bb-0-md cws-pb-24 cws-pb-0-md cws-pr-24-md"
            col="12"
            md="6"
          >
            <CwsH3 className="cws-mb-16">
              {translate('subscription.benefits.title')}{' '}
              <CwsSpan weight={700} color="midnight">
                {translate('subscription.benefits.title.particle')}
              </CwsSpan>
            </CwsH3>
            <CwsList variant="check">
              <CwsListItem iconColor="buoy">{translate('subscription.benefits.point.one')}</CwsListItem>
              <CwsListItem iconColor="buoy">{translate('subscription.benefits.point.two')}</CwsListItem>
              <CwsListItem iconColor="buoy">{translate('subscription.benefits.point.three')}</CwsListItem>
            </CwsList>
            <CwsDivider hidden spacing="s" />
            <CwsGridRow noGutter verticalAlign="center">
              <CwsGridCol col="12" sm="auto">
                <CwsButton
                  className="cws-mb-xs cws-mb-0-md cws-mr-16-sm"
                  data-analytics-id="Join Club One"
                  href={clubOneLink}
                  targetBlank
                >
                  {translate('subscription.benefits.button')}
                </CwsButton>
              </CwsGridCol>
              <CwsGridCol col="12" sm="auto">
                <CwsA color="buoy-link" data-analytics-id="Find out more" href={oneMoreLink}>
                  {translate('subscription.benefits.more')}
                </CwsA>
              </CwsGridCol>
            </CwsGridRow>
          </CwsGridCol>
          <CwsGridCol className="cws-mt-24 cws-mt-0-md cws-pl-24-md" col="12" md="6">
            <CwsH3 className="cws-mb-xs">
              {translate(isGloballyPricelist() ? 'subscription.title.pricelist' : 'subscription.title')}{' '}
              <CwsSpan weight="700">
                {translate(
                  isGloballyPricelist() ? 'subscription.title.particle.pricelsit' : 'subscription.title.particle'
                )}
              </CwsSpan>
            </CwsH3>
            <CwsP className="cws-mb-s">{translate('subscription.description')}</CwsP>
            <CwsForm>
              <CwsGridRow noGutter verticalAlign="bottom">
                <CwsGridCol
                  className="cws-mb-16 cws-mb-0-sm cws-mb-16-md cws-mb-0-lg"
                  col="12"
                  sm="fluid"
                  md="12"
                  lg="fluid"
                >
                  <CwsInput
                    id="email"
                    type="email"
                    value={emailValue}
                    label={translate('subscription.email.label')}
                    invalid={isInvalid}
                    onChange={handleChangeEmailValue}
                  />
                </CwsGridCol>
                <CwsGridCol className="cws-ml-16-sm cws-ml-0-md cws-ml-16-lg" col="12" sm="auto" md="12" lg="auto">
                  <CwsGridRow noGutter verticalAlign="bottom">
                    <CwsGridCol col="auto">
                      <CwsButton onClick={changeVisibleModal}>{translate('subscription.button')}</CwsButton>
                    </CwsGridCol>
                    <CwsGridCol className="cws-ml-16 relative" col="auto">
                      <div className="device1024:static device800:absolute device600:static device360:absolute  bottom-1.5 cws-ml">
                        <CwsImg width="82" height="66" alt="Opt In" src="/email-illustration.svg" />
                      </div>
                    </CwsGridCol>
                  </CwsGridRow>
                </CwsGridCol>
                <CwsGridCol className="cws-mt-24" col="12">
                  <CwsP hideSpacing color="dark-grey" dangerouslySetInnerHTML={{ __html: copyright }} size="tiny" />
                </CwsGridCol>
              </CwsGridRow>
            </CwsForm>
          </CwsGridCol>
        </CwsGridRow>
      </CwsGrid>
    </CwsSection>
  )
}

export default Subscription
