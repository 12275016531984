import { CwsH4, CwsP } from '@components/cws';
import moduleStyles from '@components/product/ProductViewParameters/ProductViewParameters.module.scss';
import { useTranslate } from '@hooks/useTranslation';
import { CustomAttrArray } from '@model/product';
import React, { type FC } from 'react';

interface ComponentProps {
  gender: CustomAttrArray
}

export const ProductViewParametersGender: FC<ComponentProps> = ({
                                                                 gender,
                                                               }) => {
  const { translate } = useTranslate()

  return (
    <>
      <div className={moduleStyles.gender}>
        <CwsP size="small">{translate('profile.details.gender')}: </CwsP>
        <CwsH4 size="lead" weight={500}>
          {translate(gender.value)}
        </CwsH4>
      </div>
      <hr />
    </>
  )
}
