import type { LanguageId } from '@model/locales'
import axios from 'axios'
import type { ItemToOperate, UpdateBasketItem } from '@model/cart/CartItem'

export const cartItemsFetcher = (languageId: LanguageId) => async () => {
  const { data } = await axios.post(`/api/cart/items`, {
    languageId,
  })
  return data
}

export const cartItemsAdder = (languageId: LanguageId, item: ItemToOperate) => async () => {
  const { data } = await axios.post(`/api/cart/add`, {
    languageId,
    item,
  })
  return data
}

export const cartItemsUpdater = (languageId: LanguageId, item: UpdateBasketItem) => async () => {
  const { data } = await axios.post(`/api/cart/update`, {
    languageId,
    item,
  })
  return data
}

export const cartItemsRemover = (languageId: LanguageId, item: UpdateBasketItem) => async () => {
  const { data } = await axios.post(`/api/cart/remove`, {
    languageId,
    item,
  })
  return data
}
