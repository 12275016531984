import { useInitialData } from '@context/initialDataContext';
import { usePriceInfo } from '@hooks/usePriceInfo';
import { Product } from '@model/product';
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties';
import { getPriceOnOneProducts, getPricesSum } from '@utils/product/getQuantities';
import * as R from 'ramda';

export const useCalculatedPrice = (product: Product, totalPriceBeforeCo: number, itemsQuantityAndPrices: any, allQuantities: number, bundleTypeTab: string, basePrice: number, allPriceSum: number) => {
  const {
    initialData: { allPromotions },
  } = useInitialData()
  const isPriceList = isGloballyPricelist()

  const priceInfo = usePriceInfo(product, allPromotions)

  if (!priceInfo.bundleQuantity) return totalPriceBeforeCo

  const numberOfBundles = Math.floor(allQuantities / priceInfo.bundleQuantity)

  if (numberOfBundles && itemsQuantityAndPrices && !isPriceList) {
    const pricesSum = getPricesSum(itemsQuantityAndPrices, bundleTypeTab, product.sku)

    if (Object.keys(itemsQuantityAndPrices).length <= 1) {
      return pricesSum - basePrice * numberOfBundles
    }

    const priceOnOneProducts = getPriceOnOneProducts(itemsQuantityAndPrices, bundleTypeTab, product.sku)
    const totalPriceOfFree = R.sum(priceOnOneProducts.splice(0, numberOfBundles))

    return pricesSum - totalPriceOfFree
  }

  if (isPriceList) {
    const leftOverProductPrices = (allQuantities - numberOfBundles * priceInfo.bundleQuantity) * priceInfo.topPrice
    if (numberOfBundles) return numberOfBundles * priceInfo.bundlePrice + leftOverProductPrices
  }

  if (priceInfo.bundleQuantity && allQuantities < priceInfo.bundleQuantity) {
    // return priceInfo.normalPrice * allQuantities
    return priceInfo.bundleIncludedProducts?.length ? allPriceSum : priceInfo.normalPrice * allQuantities
  }
  return priceInfo.topPrice * allQuantities
};
