import {
  CwsA,
  CwsButton,
  CwsCard,
  CwsCardBody,
  CwsCardImg,
  CwsDivider,
  CwsGrid,
  CwsGridCol,
  CwsGridRow,
  CwsP,
  CwsSection,
} from '@components/cws'
import { PriceLine, ProductImage, Rating } from '@components/product'
import { ProductTagsRow } from '@components/product/ProductCard'
import s from '@components/product/ProductCard/ProductCard.module.scss'
import { getPromotionsTag } from '@components/product/ProductCard/ProductTagsRow/tags-utils'
import ImagePlaceholder from '@components/product/ProductImage/assets/placeholder.svg'
import { useAlert } from '@context/alert'
import { useInitialData } from '@context/initialDataContext'
import { useAppState } from '@context/state'
import { useSpecialPrice } from '@hooks/product'
import { useBundleLabel } from '@hooks/useBundleLabel'
import { useCart } from '@hooks/useCart'
import { useCurrency } from '@hooks/useCurrency'
import { usePriceInfo } from '@hooks/usePriceInfo'
import { useTranslate } from '@hooks/useTranslation'
import { useWishlist } from '@hooks/useWishlist'
import { CartEvent, gaEventCart, UserData } from '@lib/gtm'
import type { Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import type { Product } from '@model/product'
import { getPriceValueWithCurrency } from '@utils/currency'
import { getCommonGaDto } from '@utils/getCommonGaDto'
import {
  getCustomAttrCustomFacetCampaign,
  getCustomAttrCustomFacetLabel,
} from '@utils/getCustomAttrCustomFacetCampaign'
import { getFullImageUrl } from '@utils/getFullImageUrl'
import { getProductTitle } from '@utils/getProductTitle'
import { getFormattedPrice } from '@utils/price'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'
import { oncePerBasket } from '@utils/product-utils'
import { cartIncludesGalaTicket } from '@utils/product/cartIncludesGalaTicket'
import { getPromotionDF } from '@utils/product/getPromotionDF'
import { isAlcoholicProduct } from '@utils/product/isAlcoholicProduct'
import { getFreeProductInfo } from '@utils/product/old-price-utils'
import { enhanceOriginalProduct } from '@utils/product/product-info'
import { getSlug } from '@utils/slugs'
import { AppRoutes } from 'app/routes'
import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as R from 'ramda'
import type { DetailedHTMLProps, FC, HTMLAttributes, PropsWithChildren } from 'react'
import { useCallback, useMemo } from 'react'
import type { ProductItemBaseProps } from '../types'

const ProductCard: FC<ProductItemBaseProps> = (props) => {
  const { product, listName, index } = props

  const {
    manufacturerName,
    name: title,
    rating: ratingValue = 0,
    sku,
    id,
    image: imageRelativePath,
    customAttrArray: customAttrs,
    numberReviews,
    quantity,
    status,
    promotionResults,
  } = product

  const {
    locale,
    query: { catalogId = '' },
  } = useRouter()

  const {
    state: { customer },
  } = useAppState()

  const {
    initialData: { allPromotions, currencies },
  } = useInitialData()

  const isPricelist = isGloballyPricelist()
  const priceInfo = usePriceInfo(product, allPromotions)
  const enhancedProduct = enhanceOriginalProduct(product, KK_LANG_IDS[locale as Locale], allPromotions)
  const image = useMemo(() => getFullImageUrl(imageRelativePath?.replace('_big', '_medium')), [imageRelativePath])

  const { showAlert } = useAlert()
  const { addToCartWithSideEffects } = useCart(isPricelist ? 'shopping list' : 'shopping cart')
  const { addToWishlist, wishlist } = useWishlist()

  const handleAddAction = async () => {
    if (isPricelist) {
      const alreadyAdded = R.compose(R.propOr(0, 'quantityDesired'), R.find(R.propEq('productId', id)))(wishlist)
      const quantityDesired = 1 + (alreadyAdded as number)

      const added = await addToWishlist({
        productId: id,
        product: {
          sku,
        },
        quantityDesired,
      })
      gaEventAddToWishlist(product)

      if (added) {
        showAlert({ text: translate('s2.wishlist.items.added') as string, hideDelay: 4000 })
      } else {
        showAlert({ error: true, text: translate('basket.items.add.error.general') as string, hideDelay: 4000 })
      }
    } else {
      await addToCartWithSideEffects({ productId: id, sku, quantity: 1 })
      gaEventAddToCart(product)
    }
  }

  const calculatedTitle = getProductTitle(product)

  const { translate } = useTranslate()

  const LinkWrap = useCallback(
    ({
      children,
    }: PropsWithChildren<{
      anchorProps?: DetailedHTMLProps<HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
    }>) => {
      const productSlug = getSlug(title, sku)
      const href = catalogId
        ? `/${catalogId}${AppRoutes.productDetails.get(productSlug)}`
        : AppRoutes.productDetails.get(productSlug)
      return (
        <Link href={href} passHref legacyBehavior>
          <CwsA
            hideArrow
            href={href}
            textAlign="center"
            onClick={() => {
              gaEventSelectItem()
            }}
          >
            {children}
          </CwsA>
        </Link>
      )
    },
    [sku, title, catalogId]
  )

  const LinkWrapWithoutAnchor = useCallback(
    ({ children }: PropsWithChildren) => {
      const productSlug = getSlug(title, sku)

      const href = catalogId
        ? `/${catalogId}${AppRoutes.productDetails.get(productSlug)}`
        : AppRoutes.productDetails.get(productSlug)
      return (
        <Link href={href} passHref legacyBehavior>
          <CwsA
            href={href}
            color="buoy-link"
            textAlign="center"
            className="cws-py-xs"
            onClick={() => {
              gaEventSelectItem()
            }}
          >
            {children}
          </CwsA>
        </Link>
      )
    },
    [sku, title, catalogId]
  )

  const campaign = getCustomAttrCustomFacetCampaign(customAttrs)
  const label = getCustomAttrCustomFacetLabel(customAttrs)

  const { promotionId: giftPromotionId } = getFreeProductInfo(product)
  const bundleLabel = useBundleLabel(priceInfo, campaign, translate)

  const isOutOfStock = !(product.sku.search('VAR') > 1) && (!quantity || quantity < 1)
  const isDisabled = status === 0

  const { currency } = useCurrency()

  let { promoPrice } = enhancedProduct
  const { normalPrice, topPrice, bundleType } = priceInfo
  const discountedPrice = normalPrice > topPrice ? topPrice : 0

  const hideNormalPrice = isAlcoholicProduct(product.custom3)

  const { cart: cartItems = [] } = useCart('shopping cart')

  const isGalaTicketInBasket = useMemo(
    () => oncePerBasket(sku) && Array.isArray(cartItems) && cartIncludesGalaTicket(cartItems),
    [cartItems, sku]
  )

  if (promoPrice) promoPrice = getPriceValueWithCurrency(promoPrice, currency, currencies)

  let specialPrice = (useSpecialPrice(product) && priceInfo.specialPrice) || promoPrice
  if (discountedPrice && !specialPrice) specialPrice = discountedPrice

  const isActiveCampaign = !isGloballyPricelist()
    ? campaign && !!campaign.length
    : campaign && !!campaign.length && !!specialPrice

  const gaEvent = (product: Product, eventName: CartEvent) => {
    const productUrlArr = product.url.split('/')
    const discount = normalPrice - (specialPrice || normalPrice)
    const category = productUrlArr[1]
    const category2 = productUrlArr[2]
    const commonDto = getCommonGaDto(product, allPromotions, bundleLabel, translate)

    const item = {
      ...commonDto,
      price: normalPrice.toString(),
      discount: discount ? discount.toFixed(2).toString() : '',
      currency: currency,
      item_category: category ?? '',
      item_category2: category2 ?? '',
      item_list_id: listName,
      index: index ?? 1,
      quantity: '1',
    }

    gaEventCart(eventName, item, customer, specialPrice || normalPrice)
  }
  const gaEventAddToCart = (product: Product) => {
    gaEvent(product, 'add_to_cart')
  }
  const gaEventAddToWishlist = (product: Product) => {
    gaEvent(product, 'add_to_wishlist')
  }
  const gaEventSelectItem = () => {
    gaEvent(product, 'select_item')
  }

  const specialPriceKey = getPromotionDF(product.promotionResults)

  return (
    <>
      <CwsSection variant="div">
        <ProductTagsRow
          giftPromotionId={giftPromotionId}
          campaign={isActiveCampaign ? campaign : undefined}
          label={label}
          bundleLabel={isPricelist ? bundleLabel : bundleType}
          promotionResults={promotionResults}
        />
      </CwsSection>
      <CwsCard>
        <CwsCardImg>
          <LinkWrap>
            {imageRelativePath ? (
              <ProductImage src={image} alt={product.sku} />
            ) : (
              <CwsSection className={s.defaultImage}>
                <ImagePlaceholder width={195} height={130} className="object-contain" />
              </CwsSection>
            )}
          </LinkWrap>
        </CwsCardImg>
        <CwsCardBody>
          <CwsP className="cws-mb-4" hideSpacing textAlign="center">
            {manufacturerName}
          </CwsP>
          <LinkWrap>{calculatedTitle}</LinkWrap>
          <CwsGrid className={clsx('cws-mb-s', !!ratingValue && 'cws-mt-tiny')}>
            <CwsGridRow justifyContent="center" noGutter>
              {!!ratingValue && <Rating value={ratingValue} hideNumber={!numberReviews} number={numberReviews} />}
            </CwsGridRow>
          </CwsGrid>
          <CwsDivider variant="dashed" />
          <CwsGrid className="cws-py-xs" width="fluid">
            <CwsGridRow noGutterVertical>
              <CwsGridCol className={!!specialPrice && 'cws-mb-tiny'} xs="12">
                <PriceLine
                  label={specialPrice ? translate(specialPriceKey) : translate('w2.product.tile.price.normal')}
                  value={specialPrice ? specialPrice : normalPrice}
                  size="sm"
                  labelClassName="text-sm"
                  valueColor={specialPrice ? 'notice' : 'midnight'}
                />
              </CwsGridCol>
              <CwsGridCol xs="12">
                <CwsGridRow justifyContent="space-between">
                  {!!specialPrice && !hideNormalPrice && (
                    <>
                      <CwsGridCol col="auto">
                        <CwsP color="twilight" hideSpacing size="small">
                          {translate('wishlist.email.price.normal')}
                        </CwsP>
                      </CwsGridCol>
                      <CwsGridCol col="auto">
                        <CwsP color="twilight" hideSpacing weight="500">
                          {getFormattedPrice(normalPrice, currency)}
                        </CwsP>
                      </CwsGridCol>
                    </>
                  )}
                </CwsGridRow>
              </CwsGridCol>
            </CwsGridRow>
          </CwsGrid>
          <CwsDivider variant="dashed" />
          {isPricelist && (
            <>
              <CwsButton
                disabled={isGalaTicketInBasket}
                onClick={handleAddAction}
                className="cws-mt-s"
                variant="secondary"
                width="fluid"
              >
                {translate('product.tile.add.to.shopping.list')}
              </CwsButton>
              <LinkWrapWithoutAnchor>{translate('order.history.details.open.details')}</LinkWrapWithoutAnchor>
            </>
          )}
          {!isPricelist && (
            <>
              {isOutOfStock || isDisabled ? (
                <CwsGrid className="cws-py-xs" width="fluid">
                  <CwsButton variant="secondary" size="medium" width="fluid">
                    {translate('product.tile.out.of.stock')}
                  </CwsButton>
                  <LinkWrapWithoutAnchor>{translate('order.history.details.open.details')}</LinkWrapWithoutAnchor>
                </CwsGrid>
              ) : (
                <>
                  <CwsButton
                    disabled={isGalaTicketInBasket}
                    onClick={handleAddAction}
                    className="cws-mt-s add-to-cart-btn"
                    variant="secondary"
                    width="fluid"
                  >
                    {translate('common.add.to.cart')}
                  </CwsButton>
                  <LinkWrapWithoutAnchor>{translate('order.history.details.open.details')}</LinkWrapWithoutAnchor>
                </>
              )}
            </>
          )}
        </CwsCardBody>
      </CwsCard>
    </>
  )
}

export default ProductCard
