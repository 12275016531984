import {
  CwsA,
  CwsAlert,
  CwsButton,
  CwsCounter,
  CwsDivider,
  CwsGrid,
  CwsGridCol,
  CwsGridRow,
  CwsP,
} from '@components/cws'
import { FreeProductBar } from '@components/product/FreeProductBar/FreeProductBar'
import { getPromotionsTag } from '@components/product/ProductCard/ProductTagsRow/tags-utils'
import BundleAndBulk from '@components/product/ProductViewParameters/BundleAndBulk'
import OnBoardAvailability from '@components/product/ProductViewParameters/OnBoardAvailability'
import { ProductViewParametersGender } from '@components/product/ProductViewParameters/ProductViewParametersGender'
import { ProductViewParametersIsOutOfStock } from '@components/product/ProductViewParameters/ProductViewParametersIsOutOfStock'
import { ProductViewParametersPrice } from '@components/product/ProductViewParameters/ProductViewParametersPrice'
import { buildGaEventDto } from '@components/product/ProductViewParameters/util/buildGaEventDto'
import { useHandleAddToCart } from '@components/product/ProductViewParameters/util/usehandleAddToCart'
import { useAlert } from '@context/alert'
import { useInitialData } from '@context/initialDataContext'
import { useAppState } from '@context/state'
import { getHandleSelectVariant } from '@hooks/handleSelectVariant'
import { useCalculatedPrice } from '@hooks/useCalculatedPrice'
import { useCart } from '@hooks/useCart'
import { useCurrency } from '@hooks/useCurrency'
import { useDiscountValue } from '@hooks/useDiscountValue'
import { useItemsQuantityAndPrices } from '@hooks/useItemsQuantityAndPrices'
import { usePriceCalculator } from '@hooks/usePriceCalculator'
import { usePriceInfo } from '@hooks/usePriceInfo'
import { useProductViewParametersProductBuilder } from '@hooks/useProductViewParametersProductBuilder'
import { useSpendableCoPoints } from '@hooks/useSpendableCoPoints'
import { useTranslate } from '@hooks/useTranslation'
import { useWishlist } from '@hooks/useWishlist'
import type { CartEvent } from '@lib/gtm'
import { gaEventCart } from '@lib/gtm'
import type { Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import type { Product, VariantInfo } from '@model/product'
import type { Variant } from '@model/product/VariantsInfo'
import { Typography } from '@ui/components'
import { getFormattedPrice, getMaxQuantity } from '@utils/price'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'
import { isSkuForbiddenToAddToCart, oncePerBasket } from '@utils/product-utils'
import { cartIncludesGalaTicket } from '@utils/product/cartIncludesGalaTicket'
import { getAlreadyAddedCount } from '@utils/product/getAlreadyAddedCount'
import { getProductIdOfBundle } from '@utils/product/getProductIdOfBundle'
import { getAllPriceSum, getAllQuantities, getCartItemsQuantity, getSkus } from '@utils/product/getQuantities'
import { enhanceOriginalProduct } from '@utils/product/product-info'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { PriceLine } from '..'
import { ProductViewVariants } from '../ProductViewVariants'
import moduleStyles from './ProductViewParameters.module.scss'

export interface ProductViewParametersProps {
  product: Product
  activeCampaign?: boolean
}

export type BundleTypeTab = 'bundle' | 'single'

const ProductViewParameters: FC<ProductViewParametersProps> = (props) => {
  const { product, activeCampaign } = props
  const {
    initialData: { allPromotions },
  } = useInitialData()

  const {
    locale,
    query: { catalogId = '' },
  } = useRouter()

  const {
    state: { customer },
  } = useAppState()

  const isPriceList = isGloballyPricelist()
  const enhancedProduct = enhanceOriginalProduct(product, KK_LANG_IDS[locale as Locale], allPromotions)
  const priceInfo = usePriceInfo(product, allPromotions)

  const {
    itemsQuantityState,
    dispatch,
    readyProduct: {
      promoPriceKey,
      isAlcoholItem,
      freeProductResults,
      gender,
      hasVariants,
      initiallySelectedVariant,
      promotionInfo,
      discount,
    },
  } = useProductViewParametersProductBuilder(enhancedProduct)

  const itemsQuantityAndPrices = useItemsQuantityAndPrices(itemsQuantityState, product)
  const { normalPrice } = priceInfo
  const { variantInfo, status } = product
  const { currency } = useCurrency()
  const [isOpenCheckEmail, setIsOpenCheckEmail] = useState<boolean>(false)
  const [bundleTypeTab, setBundleTypeTab] = useState<BundleTypeTab>('bundle')
  const { cart: cartItems = [] } = useCart('shopping cart')

  const isGalaTicketInBasket = useMemo(
    () => oncePerBasket(product.sku) && Array.isArray(cartItems) && cartIncludesGalaTicket(cartItems),
    [cartItems, product]
  )

  const [selectedVariant, setSelectedVariant] = useState(initiallySelectedVariant)
  const maxQuantity = selectedVariant ? selectedVariant.quantity : product.quantity
  const isOutOfStock = status === 0 || !maxQuantity || maxQuantity < 1

  const { translate } = useTranslate()

  useEffect(() => {
    setIsOpenCheckEmail(false)
  }, [locale])

  const handleQuantityUpdate = useCallback(
    (value: number) => {
      dispatch({
        type: 'Set',
        payload: {
          sku: product.sku,
          value,
        },
      })
    },

    [product, dispatch]
  )
  const reset = useCallback(() => {
    dispatch({
      type: 'ReSet',
      payload: {
        sku: product.sku,
        value: itemsQuantityState[product.sku],
      },
    })
  }, [product, dispatch, itemsQuantityState])

  const itemQuantityCallback = useCallback(
    (sku: string, value: number) => {
      dispatch({
        type: 'Set',
        payload: {
          sku,
          value,
        },
      })
    },
    [dispatch]
  )

  const { cart } = useCart('shopping cart')

  const currentProductQuantity = itemsQuantityState[product.sku] || 1
  const allQuantities = getAllQuantities(itemsQuantityState, bundleTypeTab, product.sku)
  const allPriceSum = getAllPriceSum(itemsQuantityAndPrices, bundleTypeTab, product.sku)
  const cartItemsQuantity = getCartItemsQuantity(cart)

  const { addToWishlist, wishlist } = useWishlist()
  const { showAlert } = useAlert()

  const handleAddToWishlist = async () => {
    const skus = getSkus(itemsQuantityState, bundleTypeTab, product.sku)

    for (const sku of skus) {
      if (sku && itemsQuantityState[sku]) {
        const productId = getProductIdOfBundle(product, priceInfo, sku)
        const alreadyAdded = getAlreadyAddedCount(wishlist, productId)
        const quantity = itemsQuantityState[sku] + (alreadyAdded as number)
        const added = await addToWishlist({ productId, quantityDesired: quantity, product: { sku } })

        if (added) {
          showAlert({ text: translate('s2.wishlist.items.added') as string, hideDelay: 4000 })
          gaEvent(product, quantity, 'add_to_wishlist')
        } else {
          showAlert({ error: true, text: translate('basket.items.add.error.general') as string, hideDelay: 4000 })
        }
      }
    }
  }

  const handleSelectVariant = (variant: Variant) => {
    setSelectedVariant(variant)
    getHandleSelectVariant(variant, catalogId)
  }

  const { priceByWeight, totalPriceBeforeCo, specialPrice, basePrice, totalPriceAfterCo, totalPriceForCalculateCo } =
    usePriceCalculator(enhancedProduct, promotionInfo, allQuantities)

  const gaEvent = (product: Product, quantity: number, eventName: CartEvent) => {
    try {
      const { item } = buildGaEventDto(
        product,
        allPromotions,
        normalPrice,
        eventName,
        currency,
        basePrice,
        quantity,
        translate
      )
      gaEventCart(eventName, item, customer, basePrice)
    } catch (e) {
      console.error(e)
    }
  }

  const { handleAddToCart } = useHandleAddToCart(
    itemsQuantityState,
    enhancedProduct,
    cartItemsQuantity,
    bundleTypeTab,
    gaEvent,
    maxQuantity
  )

  const calculatedPrice = useCalculatedPrice(
    product,
    totalPriceBeforeCo,
    itemsQuantityAndPrices,
    allQuantities,
    bundleTypeTab,
    basePrice,
    allPriceSum
  )

  const spendableCoPoints = useSpendableCoPoints(totalPriceForCalculateCo, product)
  const discountValue = useDiscountValue(enhancedProduct, discount, allQuantities)
  const { description: promotionDescription } = getPromotionsTag(allPromotions)(product.promotionResults)

  return (
    <div className={clsx(moduleStyles.container)}>
      {hasVariants && selectedVariant && (
        <>
          <ProductViewVariants
            variantsInfo={variantInfo as VariantInfo}
            selectedVariant={selectedVariant}
            onSelectVariant={handleSelectVariant}
          />
          <hr />
        </>
      )}
      {!!gender && <ProductViewParametersGender gender={gender} />}

      {((Boolean(specialPrice) || activeCampaign) && basePrice < normalPrice) ||
      (allQuantities >= priceInfo.bundleQuantity && priceInfo.bundleType) ? (
        <>
          <div className={'flex flex-col device600: py-4 space-y-lg'}>
            <div className="flex flex-col gap-y-2">
              <div className="flex gap-x-2 items-start device600:px-4 ">
                <CwsGrid className="cws-pt-xs" width="fluid">
                  {/* fix here */}
                  <PriceLine
                    label={translate(promoPriceKey)}
                    value={basePrice}
                    valueColor={!priceInfo.bundleType ? 'notice' : 'midnight'}
                    discountValue={!isAlcoholItem && discountValue && isGloballyPricelist() ? discountValue : undefined}
                  />
                  {!isAlcoholItem && !priceInfo.bundleType && (
                    <CwsGridRow justifyContent="space-between">
                      <CwsGridCol>
                        <span className="flex gap-2 justify-end">
                          <CwsP size="small" color="dark-grey">
                            {translate('w2.product.title.regular')} :{' '}
                          </CwsP>
                          <CwsP size="small" weight="500" color="dark-grey cws-m-0">
                            {getFormattedPrice(normalPrice, currency)}
                          </CwsP>
                        </span>
                      </CwsGridCol>
                    </CwsGridRow>
                  )}
                </CwsGrid>
              </div>

              {Boolean(priceByWeight && !isAlcoholItem) && (
                <span className="flex justify-end device600:px-4">
                  <div className={clsx(moduleStyles.litrePrice)}>
                    <CwsP size="small" hideSpacing>
                      {priceByWeight}
                    </CwsP>
                  </div>
                </span>
              )}
            </div>
          </div>
          <hr />
        </>
      ) : (
        <>
          <div className={clsx(moduleStyles.section, moduleStyles.priceSection)}>
            <CwsGrid className="cws-py-xs" width="fluid">
              <PriceLine label={translate('w2.product.tile.price.normal')} value={normalPrice} />
            </CwsGrid>
            {Boolean(priceByWeight) && (
              <div className={clsx(moduleStyles.litrePrice)}>
                <CwsP size="small" hideSpacing>
                  {priceByWeight}
                </CwsP>
              </div>
            )}
          </div>
          <hr />
        </>
      )}
      {Boolean(isPriceList ? priceInfo.bundleType : product.priceInfo.bundleType) ? (
        <BundleAndBulk
          product={product}
          itemsQuantityState={itemsQuantityState}
          handleQuantityUpdate={handleQuantityUpdate}
          itemQuantityCallback={itemQuantityCallback}
          productQuantity={currentProductQuantity}
          bundleTypeTab={bundleTypeTab}
          setTab={setBundleTypeTab}
          reset={reset}
        />
      ) : (
        <div className={moduleStyles.section}>
          <div className={moduleStyles.parameter}>
            <Typography>{translate('product.detail.quantity.title')}:</Typography>
            <CwsCounter
              insert
              id={'product-quantity'}
              onChange={handleQuantityUpdate}
              value={currentProductQuantity}
              max={oncePerBasket(product.sku) ? 1 : getMaxQuantity(maxQuantity)}
              min={1}
            />
          </div>
          {product.info?.isLowStock && !isOutOfStock && (
            <CwsAlert variant="inline" type="info">
              <CwsP size="small">{translate('product.detail.quantity.info')}</CwsP>
            </CwsAlert>
          )}
        </div>
      )}
      {Boolean(product.custom5) && <OnBoardAvailability shipsListString={product.custom5} />}
      <CwsDivider variant="solid" />

      {isPriceList ? (
        <div className={moduleStyles.section}>
          <div className="flex flex-col space-y-sm">
            <CwsGrid className="cws-py-xs" width="fluid">
              <PriceLine label={`${translate('product.detail.price.total')}:`} value={calculatedPrice} />
            </CwsGrid>
          </div>
        </div>
      ) : (
        <ProductViewParametersPrice
          product={product}
          calculatedPrice={calculatedPrice}
          totalPriceAfterCo={totalPriceAfterCo}
          priceInfo={priceInfo}
          spendableCoPoints={spendableCoPoints}
          freeProductResults={freeProductResults}
          promotionDescription={promotionDescription}
        />
      )}

      <FreeProductBar product={product} />
      {!isPriceList && (
        <div className="flex flex-col space-y-sm items-stretch px-lg py-xl cws-bg--color-seafoam">
          {isOutOfStock ? (
            <ProductViewParametersIsOutOfStock
              product={product}
              isOpenCheckEmail={isOpenCheckEmail}
              setIsOpenCheckEmail={setIsOpenCheckEmail}
            />
          ) : (
            <div className="flex flex-col product-options">
              <CwsButton
                disabled={isGalaTicketInBasket}
                className={clsx(isSkuForbiddenToAddToCart(product.sku) && 'invisible')}
                size="medium"
                variant="primary"
                width="fluid"
                onClick={handleAddToCart}
              >
                {translate('common.add.to.cart')}
              </CwsButton>
              <CwsA locale={locale} textAlign="center" className="mt-4" onClick={handleAddToWishlist} href={null}>
                {translate('product.tile.add.to.shopping.list')}
              </CwsA>
            </div>
          )}
        </div>
      )}
      {isPriceList && (
        <div className="flex flex-col space-y-sm items-stretch px-lg py-xl cws-bg--color-seafoam">
          <div className="flex flex-col product-options">
            <CwsButton
              disabled={isGalaTicketInBasket}
              size="medium"
              variant="primary"
              width="fluid"
              onClick={handleAddToWishlist}
            >
              {translate('product.tile.add.to.shopping.list')}
            </CwsButton>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProductViewParameters
