import { Product } from '@model/product'
import { getClubOnePointsAvailable } from '@utils/product/product-info'
import { PERCENTAGE_OF_POINT_USED } from '../constants/product'

export const useSpendableCoPoints = (totalPriceBeforeCo: number, product: Product) => {
  return getClubOnePointsAvailable(
    totalPriceBeforeCo,
    product.priceInfo.coRate,
    parseFloat(product[PERCENTAGE_OF_POINT_USED])
  )
}
