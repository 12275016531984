import { extractRenderReadyProduct } from '@components/product/ProductViewParameters/util/extractRenderReadyProduct';
import { useInitialData } from '@context/initialDataContext';
import { useMultipleItemsQuantity } from '@hooks/useMultipleItemsQuantity';
import { usePriceInfo } from '@hooks/usePriceInfo';
import type { Product } from '@model/product';

/* Essentially builds data for the view - does computations before hand*/
export const useProductViewParametersProductBuilder = (product: Product) => {
  const { itemsQuantityState, dispatch } = useMultipleItemsQuantity(product.sku)
  const readyProduct = extractRenderReadyProduct(product);

  return {
    itemsQuantityState,
    dispatch,
    readyProduct,
  }
}

