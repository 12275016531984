export const clothesSizeToNumber = (size: string): number => {
  switch (size?.toUpperCase()) {
    case 'XXXXXS':
      return -6
    case 'XXXXS':
      return -5
    case 'XXXS':
      return -4
    case 'XXS':
      return -3
    case 'XS':
      return -2
    case 'S':
      return -1
    case 'M':
      return 0
    case 'L':
      return 1
    case 'XL':
      return 2
    case 'XXL':
      return 3
    case 'XXXL':
      return 4
    case 'XXXXL':
      return 5
    case 'XXXXXL':
      return 6
    default:
      return 0
  }
}
