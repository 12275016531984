import { Product } from '@model/product';
import { getDiscount } from '@utils/product/getDiscount';
import { getGender } from '@utils/product/getGender';
import { getPromotionDF } from '@utils/product/getPromotionDF';
import { hasMoreThanOneVariant } from '@utils/product/hasMoreThanOneVariant';
import { initiallySelectedVariant } from '@utils/product/initiallySelectedVariant';
import { isAlcoholicProduct } from '@utils/product/isAlcoholicProduct';
import { getFreeProductInfo } from '@utils/product/old-price-utils';

export const extractRenderReadyProduct = (product: Product) => {
  const promotionInfo = getPromotionInfo(product);

  return ({
    promoPriceKey: getPromotionDF(product.promotionResults),
    freeProductResults: getFreeProductInfo(product),
    gender: getGender(product),
    isOutOfStock: false,
    hasVariants: hasMoreThanOneVariant(product.variantInfo),
    initiallySelectedVariant: initiallySelectedVariant(product),
    promotionInfo: promotionInfo,
    promotionPrice: 10, // todo
    discount: getDiscount(product),
    isAlcoholItem: isAlcoholicProduct(product.custom3),
  });
}

const getPromotionInfo = (product: Product) => product?.productPromotion.find((promo) => promo.id === product.promotionResults?.[0]?.promotionId)

