import { useCartState } from '@components/cart/State/state'
import { getCommonGaDto } from '@utils/getCommonGaDto';
import { getCustomAttrCustomFacetCampaign, getCustomAttrCustomFacetLabel } from '@utils/getCustomAttrCustomFacetCampaign';
import { useEffect, useMemo } from 'react'
import { getCurrencyOptByLanguageId, useCurrency } from '@hooks/useCurrency'
import { CustomFacetNumber } from '@model/product/CustomFacet'
import { useTranslate } from '@hooks/useTranslation'
import { getPromotionsTag } from '@components/product/ProductCard/ProductTagsRow/tags-utils'
import { useRouter } from 'next/dist/client/router'
import { KK_STORE } from '@api/apiConfig'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'
import { KK_LANG_IDS, Locale } from '@model/locales'
import { isPromotionValidForCatalog } from '@utils/promotion'
import { Catalog } from '@model/pricelist/Catalog'
import { getPrices } from '@utils/product/product-info'
import { KK_STORE_TYPE } from '@pages/api/proxy'
import { useCart } from '@hooks/useCart'
import { getGiftCouponsAppliedTotal } from '@utils/order'
import { useAppState } from '@context/state'
import { UserData } from '@lib/gtm'
import { useInitialData } from '@context/initialDataContext'
export const useCartGaEvents = (disabled = false) => {
  const {
    state: { order, paymentMethod, coupons, giftRecipientContactData, ...state },
  } = useCartState()
  const { cart, isLoading } = useCart('shopping cart')
  const { translate } = useTranslate()
  const { currency } = useCurrency()

  const {
    initialData: { allPromotions, currencies },
  } = useInitialData()
  const {
    state: { customer },
  } = useAppState()
  const user: UserData | null = customer
    ? {
        user_email: customer.emailAddr,
        user_phone: customer.telephoneNumber,
        user_id: customer.id,
      }
    : null
  const {
    locale,
    query: { catalogId },
  } = useRouter()

  const storeId = KK_STORE || isGloballyPricelist() ? 'store1' : 'store2'

  const items = useMemo(() => {
    if (disabled && isLoading) return []

    return (
      cart?.map((el) => {
        const priceInfo = () => {
          try {
            const languageId = isGloballyPricelist() ? 9 : KK_LANG_IDS[locale as Locale]
            const currencyOpt = getCurrencyOptByLanguageId(languageId, currencies)
            const filteredPromotions = allPromotions.filter(isPromotionValidForCatalog((catalogId as Catalog) || ''))

            return getPrices(
              el.product,
              storeId as KK_STORE_TYPE,
              locale as string,
              filteredPromotions,
              currencyOpt,
              '1'
            )
          } catch (e) {
            console.error(e)
          }
        }

        const { topPrice = 0, normalPrice = 0 } = priceInfo() ?? { topPrice: 0, normalPrice: 0 }
        const discount = normalPrice - topPrice

        try {
          const commonDto = getCommonGaDto(el.product, allPromotions, null, translate)

          const productUrlArr = el.product?.url?.split('/')
          const category = productUrlArr?.[1] ?? ''
          const category2 = productUrlArr?.[2] ?? ''
          return {
            ...commonDto,
            price: topPrice.toString(),
            discount: discount ? discount.toFixed(2).toString() : '',
            currency,
            item_category: category,
            item_category2: category2,
            item_variant: el.product.model,
            quantity: el.quantity.toString(),
          }
        } catch (e) {
          console.error(e)
        }
      }) ?? []
    )
  }, [currency, disabled, order?.orderProducts])

  const addShippingInfoGaEvent = (deliveryVariant: string) => {
    try {
      window.dataLayer.push({
        event: 'add_shipping_info',
        shipping_tier: deliveryVariant,
        ecommerce: {
          items: [...items],
        },
        ...(user ? { user: user } : {}),
      })
    } catch (e) {
      console.error(new Error('an error occurred while executing the add_shipping_info event'))
    }
  }

  const viewCartGaEvent = () => {
    try {
      window.dataLayer.push({
        event: 'view_cart',
        ecommerce: {
          items: [...items],
        },
        ...(user ? { user: user } : {}),
      })
    } catch (e) {
      console.error(new Error('an error occurred while executing the view_cart event'))
    }
  }
  const beginCheckoutGaEvent = () => {
    if (!order) return
    let value = getGiftCouponsAppliedTotal(order).totalValueAfterCoupons ?? order.totalIncTax
    if (value < 0) value = 0

    try {
      window.dataLayer.push({
        event: 'begin_checkout',
        coupon: order?.couponCode ? 'blackfriday' : '',
        gift_card: order?.giftCertCode ? 'true' : '',
        value,
        ecommerce: {
          items: [...items],
        },
        ...(user ? { user: user } : {}),
      })
    } catch (e) {
      console.error(new Error('an error occurred while executing the begin_checkout event'))
    }
  }

  const addPaymentInfoGaEvent = () => {
    try {
      window.dataLayer.push({
        event: 'add_payment_info',
        coupon: coupons.length ? 'blackfriday' : '',
        gift_card_amount: order?.giftCertCode ?? '',
        club_one_points: order?.pointsRedeemed ? 'true' : '',
        payment_type: paymentMethod,
        ecommerce: {
          items: [...items],
        },
        ...(user ? { user: user } : {}),
      })
    } catch (e) {
      console.error(new Error('an error occurred while executing the add_payment_info event'))
    }
  }

  return {
    addShippingInfoGaEvent,
    viewCartGaEvent,
    beginCheckoutGaEvent,
    addPaymentInfoGaEvent,
  }
}
