import { Customer } from '@model/customer/Customer';
import type { Product } from '@model/product'
import type { Currency } from '@model/product/PriceInfo'
import { getBadge } from '@utils/getBadge';
import { getCommonGaDto } from '@utils/getCommonGaDto';
import { getCustomAttrCustomFacetCampaign, getCustomAttrCustomFacetLabel } from '@utils/getCustomAttrCustomFacetCampaign';
import { getCustomerUserData } from '@utils/getUserData';
import * as R from 'ramda'
import type { Order } from '@model/order/Order'
import { getAllTotalsByClassName, getOrderTotalByClassName } from '@utils/order'
import type { Promotion } from '@model/misc/Promotion'
import { getUniqueVatLines } from '@components/cart/payment/PaymentMain/PaymentMain'
import { getPriceValueWithCurrency } from '@utils/currency'
import { CurrenciesInfo } from '@hooks/useCurrency'

export const pageview = (url: string) => {
  if ('dataLayer' in window) {
    window.dataLayer.push({
      event: 'pageview',
      page: url,
    })
  }
}

export const viewItem = (product: Product, currency: Currency, promotion: string, userData: UserData | null) => {
  const {
    promoPrice,
    priceInfo: { topPrice, normalPrice },
  } = product

  const discountedPrice = promoPrice ? R.min(promoPrice, topPrice) : R.min(topPrice, normalPrice)

  const discount = (normalPrice - discountedPrice).toFixed(2).toString()
  const productUrlArr = product.url.split('/')
  const category = productUrlArr[1]
  const category2 = productUrlArr[2]
  window.dataLayer.push({
    event: 'view_item',
    ecommerce: {
      items: [
        {
          item_name: product.name,
          item_id: product.sku,
          price: normalPrice,
          discount: discount,
          currency: currency,
          promotion_name: promotion, // name of the promotion if discounted
          item_brand: product.manufacturerName,
          item_category: category ?? '', // 1st menu level category name, english version only
          item_category2: category2 ?? '', // 2nd menu level category name, english version only
          item_variant: product.model,
          item_list_id: 'product_page',
          quantity: 1,
        },
      ],
      value: discountedPrice,
    },
    ...(userData ? { user: userData } : {}),
  })
}

export type CartEvent = 'add_to_cart' | 'remove_from_cart' | 'select_item' | 'add_to_wishlist'
export type UserData = {
  user_email: string
  user_phone: string
  user_id: number
}
interface Item {
  item_name: string
  item_id: string
  price: string
  discount: string
  currency: Currency
  promotion_name: string
  item_brand: string
  item_category: string
  item_category2: string
  item_list_id?: string
  item_variant?: string
  index?: number
  quantity: string
}
export const gaEventCart = (event: CartEvent, item: Item, customer: Customer |null, value?: number) => {
  const userData = getCustomerUserData(customer)

  window.dataLayer.push({
    event: event,
    ecommerce: {
      ...(value !== undefined ? { value: value } : {}),
      items: [item],
    },
    ...(userData ? { user: userData } : {}),
  })
}

export const addPurchaseGaEvent = (
  order: Order,
  allPromotions: Promotion[],
  translate: (term: string, params?: any) => string,
  currency: Currency,
  currencies: CurrenciesInfo[],
  userData: UserData | null
) => {
  if (!order) return
  const otGetter = getOrderTotalByClassName(order?.orderTotals ?? [])

  const paymentTypes: { [key: string]: string } = {
    adyen: 'credit-card',
    banks: 'Internet bank',
  }
  const otGetterAll = getAllTotalsByClassName(order?.orderTotals)
  const uniqueVatLines = getUniqueVatLines(otGetterAll('ot_tax')).filter((vatOt) => !!vatOt.value)

  const items =
    order?.orderProducts?.map((el) => {
      const { bundleLabel = '', topPrice, normalPrice } = el.product.priceInfo
      const normalPriceWithCurrency = getPriceValueWithCurrency(normalPrice, currency, currencies)
      const topPriceWithCurrency = getPriceValueWithCurrency(topPrice, currency, currencies)
      const discount = normalPriceWithCurrency - topPriceWithCurrency
      const productUrlArr = el.product.url?.split('/')
      const commonDto = getCommonGaDto(el.product, allPromotions, bundleLabel, translate)

      const category = productUrlArr[1]
      const category2 = productUrlArr[2]
      return {
        ...commonDto,
        price: topPriceWithCurrency.toString(),
        discount: discount ? discount.toFixed(2).toString() : '',
        currency: currency,
        item_category: category,
        item_category2: category2,
        item_variant: el.product.model,
        quantity: el.quantity.toString(),
      }
    }) ?? []
  const value = getPriceValueWithCurrency(otGetter('ot_total')?.value ?? 0, currency, currencies)
  const tex = uniqueVatLines.length
    ? uniqueVatLines.map((item) => getPriceValueWithCurrency(item.value, currency, currencies)).join(' ')
    : ''
  try {
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        coupon: otGetter('ot_gift_coupons')?.value ?? '',
        gift_card_amount: order?.giftCertCode ?? '',
        club_one_points: order?.pointsRedeemed ? 'true' : '',
        payment_type: paymentTypes[order.paymentMethod] ?? order.paymentMethod,
        transaction_id: order.orderNumber,
        value: value,
        tax: tex,
        shipping: otGetter('ot_shipping')?.value ?? '',
        items: [...items],
      },
      ...(userData ? { user: userData } : {}),
    })
  } catch (e) {
    console.error(new Error('an error occurred while executing the purchase event'))
  }
}
