import type { OptionsSelect } from '@ui/components/SelectSearch/SelectSearch'

export const alphabetOption: { [key: string]: string } = {
  ['Estonia']: 'et',
  ['Latvia']: 'lv',
  ['Lithuania']: 'lt',
}

const alphabets: { [key: string]: string[] } = {
  et: [
    'A',
    'B',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'R',
    'S',
    'Š',
    'Z',
    'Ž',
    'T',
    'U',
    'V',
    'Õ',
    'Ä',
    'Ö',
    'Ü',
  ],
  lv: [
    'A',
    'Ā',
    'B',
    'C',
    'Č',
    'D',
    'E',
    'Ē',
    'F',
    'G',
    'Ģ',
    'H',
    'I',
    'Ī',
    'J',
    'K',
    'Ķ',
    'L',
    'Ļ',
    'M',
    'N',
    'Ņ',
    'O',
    'P',
    'R',
    'S',
    'Š',
    'T',
    'U',
    'Ū',
    'V',
    'Z',
    'Ž',
  ],
  lt: [
    'A',
    'Ą',
    'B',
    'C',
    'Č',
    'D',
    'E',
    'Ę',
    'Ė',
    'F',
    'G',
    'H',
    'I',
    'Į',
    'Y',
    'J',
    'K',
    'L',
    'M',
    'N',
    'Ų',
    'O',
    'P',
    'R',
    'S',
    'Š',
    'T',
    'U',
    'Ū',
    'V',
    'Z',
    'Ž',
  ],
}

const getCharPosition = (char: string, alphabet: string[]): number => {
  return alphabet.indexOf(char.toUpperCase())
}

const compareStringsByLanguage = (str1: string, str2: string, alphabet: string[]): number => {
  const len = Math.min(str1.length, str2.length)

  for (let i = 0; i < len; i++) {
    const char1 = str1[i].toUpperCase()
    const char2 = str2[i].toUpperCase()

    const pos1 = getCharPosition(char1, alphabet)
    const pos2 = getCharPosition(char2, alphabet)

    if (pos1 !== pos2) {
      return pos1 - pos2
    }
  }

  return str1.length - str2.length
}

export const sortStringsByDetectedLanguage = (arr: OptionsSelect[], language: string): OptionsSelect[] => {
  return arr.sort((a, b) => compareStringsByLanguage(a.name, b.name, alphabets[language]))
}
