export const getBranchNameWhenDevelopmentEnv = async () => {
  if (['development', 'dev'].includes(process.env.DEPLOY_ENV || '')) {
    const regex = /SHOP-\d+|PRIC-\d+/g
    try {
      const url = `${process.env.CMS_API_ORIGIN}/deployment.json`
      const response = await fetch(url)

      if (response.ok) {
        const data = await response.json()
        if (data.repositoryBranch) {
          return data.repositoryBranch.match(regex).join('')
        }
      }
    } catch (error) {
      console.error('Error loading deployment.json:', error)
      return null
    }
  }
  return null
}
