import { CwsAlert, CwsGrid, CwsGridCol, CwsGridRow, CwsH4, CwsIcon, CwsP } from '@components/cws';
import s from '@components/product/ProductViewParameters/ProductViewParameters.module.scss';
import moduleStyles from '@components/product/ProductViewParameters/ProductViewParameters.module.scss';
import { useCurrency } from '@hooks/useCurrency';
import { useTranslate } from '@hooks/useTranslation';
import { getFormattedPrice } from '@utils/price';
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties';
import clsx from 'clsx';
import React, { type FC } from 'react';

interface ComponentProps {
  calculatedPrice: number;
  totalPriceAfterCo: number;
  spendableCoPoints: number;
  freeProductResults: any
  promotionDescription: any;
  product: any;
  priceInfo: any
}

export const ProductViewParametersPrice: FC<ComponentProps> = ({
                                                                 calculatedPrice,
                                                                 totalPriceAfterCo,
                                                                 spendableCoPoints,
                                                                 freeProductResults,
                                                                 promotionDescription,
                                                                 priceInfo,
                                                                 product,
                                                               }) => {
  const { translate } = useTranslate()
  const { currency } = useCurrency()
  const showInfoNode = promotionDescription &&
    !freeProductResults &&
    !Boolean(isGloballyPricelist() ? priceInfo.bundleType : product.priceInfo.bundleType);

  return (
    <div className={moduleStyles.section}>
      <div className="flex flex-col space-y-sm">
        <CwsGrid className="cws-py-xs" width="fluid">
          <CwsGridRow noGutterVertical justifyContent="space-between">
            <CwsGridCol col="auto">
              <CwsP hideSpacing size="small">
                {translate('product.detail.price.total')}
              </CwsP>
            </CwsGridCol>
            <CwsGridCol col="auto">
              <CwsH4 textAlign="right" size="lead">
                {getFormattedPrice(calculatedPrice, currency)}
              </CwsH4>
            </CwsGridCol>
          </CwsGridRow>
          <CwsGridRow justifyContent="right">
            <CwsGridCol col="auto">
              <CwsP size="small" textAlign="right">
                {translate('cart.info.total.or.label')}
              </CwsP>
            </CwsGridCol>
          </CwsGridRow>
          <CwsGridRow justifyContent="right">
            <CwsGridCol col="auto">
              <CwsH4 textAlign="right" size="lead">
                {getFormattedPrice(totalPriceAfterCo, currency)} + {spendableCoPoints}{' '}
                {translate('cart.copoints.available.points.after')}
              </CwsH4>
            </CwsGridCol>
          </CwsGridRow>
        </CwsGrid>
      </div>
      {showInfoNode &&
        <div className={clsx(s.readLeft, 'gap-4 py-[16px] px-6 flex flex-row items-center bg-seafoam')}>
          <CwsIcon icon="price-tag" size="24px" />
          <CwsP size="small" hideSpacing>
            {translate(promotionDescription ?? '')}
          </CwsP>
        </div>}
      <div>
        <div className="flex flex-row space-x-sm">
          <CwsAlert variant="inline" type="info">
            <CwsP size="small">{translate('product.detail.co.tooltip')}</CwsP>
          </CwsAlert>
        </div>
      </div>
    </div>
  )
}
