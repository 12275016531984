import type { FC } from 'react'
import { useEffect } from 'react'
import s from './IntegratedHeader.module.scss'
interface SSOContainerProps {
  isMobile?: boolean
  locale: string
}
export const SSOContainer: FC<SSOContainerProps> = ({ isMobile, locale }) => {
  useEffect(() => {
    return () => {
      if (window.SSO) {
        window.SSO.close()
      }
    }
  }, [])
  useEffect(() => {
    if (window.renderSSO && window.SSO) {
      window.renderSSO()
    }
  }, [isMobile, locale])
  return <div id="sso-container" className={s.ssoWrapper} />
}
