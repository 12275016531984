import s from '@components/common/Header/IntegratedHeader.module.scss'
import { CwsA, CwsHeaderNav, CwsHeaderNavItem } from '@components/cws'
import { usePreloadedInitialDataData } from '@context/PreloadedInitialDataContext'
import { keys } from 'ramda'
import React, { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'

export const UpperNavigation: React.FC = () => {
  const [isRendered, setIsRendered] = useState(true)
  const { locale } = useRouter()
  const prevLocale = useRef<string | undefined>(locale)
  const items = usePreloadedInitialDataData().topNavigation
  const navItems = keys(items)

  useEffect(() => {
    if (prevLocale.current !== locale) {
      setIsRendered(false)
      prevLocale.current = locale
    } else {
      setIsRendered(true)
    }
  }, [locale, isRendered])

  if (!isRendered) return null
  return (
    <CwsHeaderNav className={s.headerNavPreloaded}>
      {navItems.map((key, index, array) => (
        <CwsHeaderNavItem
          divider={index === array.length - 1}
          current={items[key] === '/'}
          key={key}
          href={items[key]}
          label={key}
        >
          <CwsA target="_blank" hideArrow size="small" href={items[key]}>
            {key}
          </CwsA>
        </CwsHeaderNavItem>
      ))}
    </CwsHeaderNav>
  )
}
