import useSWR from 'swr'
import { useMemo } from 'react'
import { calculateCoMax } from '@api/clubone/utils'
import { useCart } from '@hooks/useCart'
import type { Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import { getCurrencyOptByLanguageId, useCurrency } from '@hooks/useCurrency'
import { useRouter } from 'next/router'
import { useAppState } from '@context/state'
import { useCartState } from '@components/cart/State/state'
import { useInitialData } from '@context/initialDataContext'

export const useMaxCoPoint = () => {
  const { locale } = useRouter()
  const { currency } = useCurrency()
  const {
    state: { order },
  } = useCartState()

  const {
    state: { customer },
  } = useAppState()

  const {
    initialData: { currencies, allPromotions },
  } = useInitialData()
  const languageId = KK_LANG_IDS[locale as Locale]
  const currencyOptions = getCurrencyOptByLanguageId(languageId, currencies)
  const { cart: cartItems = [] } = useCart('shopping cart')
  const { data: availableCOPoints } = useSWR<number>(customer && '/api/customer/load-clubone-points')

  const maxCoPointsToSpend = useMemo(() => {
    if (!order || !availableCOPoints) return 0

    const maxPoints = calculateCoMax({
      order,
      promotions: allPromotions,
      currencyOptions,
      cartItems,
      customer,
      currency,
    })

    const coPointSum = maxPoints >= availableCOPoints ? availableCOPoints : maxPoints
    return coPointSum > 0 ? coPointSum : 0
  }, [order, allPromotions, currencyOptions, availableCOPoints, cartItems, customer])

  return {
    maxCoPointsToSpend,
  }
}
