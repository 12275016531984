import { useInitialData } from '@context/initialDataContext';
import { useCurrency } from '@hooks/useCurrency';
import { usePriceInfo } from '@hooks/usePriceInfo';
import { Product } from '@model/product';
import { getPriceValueWithCurrency } from '@utils/currency';

export const useItemsQuantityAndPrices  = (itemsQuantityState: any, product: Product) => {
  const {
    initialData: { allPromotions, currencies },
  } = useInitialData()
  const priceInfo = usePriceInfo(product, allPromotions)
  const skusInUse = Object.keys(itemsQuantityState)
  const { currency } = useCurrency()

  return priceInfo.bundleIncludedProducts?.reduce(
    (acc, val) => ({
      ...acc,
      ...(skusInUse.includes(val.sku) && {
        [val.sku]: [
          itemsQuantityState[val.sku],
          getPriceValueWithCurrency(val.price0 || 0, currency, currencies) * itemsQuantityState[val.sku],
        ],
      }),
    }),
    {} as Record<string, number[]>,
  )
}
