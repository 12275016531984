import { PriceInfo } from '@model/product/PriceInfo';

export const useBundleLabel = (priceInfo: PriceInfo, campaign: string[], translate: any) => {
  let { bundleLabel = '' } = priceInfo

  if (campaign.length && campaign.includes(bundleLabel)) {
    bundleLabel = ''
  } else if (bundleLabel && bundleLabel.includes('.')) {
    bundleLabel = translate(bundleLabel)
  }

  return bundleLabel;
}
