import type { Order } from '@model/order/Order'
import * as R from 'ramda'
import type { CurrencyOpt } from '@hooks/useCurrency'
import type { Product } from '@model/product'
import type { CartItem } from '@model/cart/CartItem'
import type { Customer } from '@model/customer/Customer'
import type { Currency } from '@model/product/PriceInfo'
import { PERCENTAGE_OF_POINT_USED } from '../../constants/product'
import { revertBackToEURCurrency } from '@utils/currency'
import { CurrenciesInfo } from '@hooks/useCurrency'
import { Promotion } from '@model/misc/Promotion'

interface Input {
  order: Order
  customer: Customer | null
  promotions: Promotion[]
  currencyOptions: CurrencyOpt
  cartItems: CartItem[]
  currency: Currency
}

const getClubOnePointsAvailable = (price: number, rate: number, percentage: number) => {
  if (price === 0 || percentage === 0) return 0
  const discountPercentage = percentage || 0
  return Math.round(((price * discountPercentage) / 100) * rate)
}

const calculateBundlePrice = (bundleQuantity: number, productsQuantity: number, itemPrice: number) => {
  return +((productsQuantity - bundleQuantity) * itemPrice).toFixed(2)
}

interface TotalsPerItem {
  total: number
  finalPriceExTax: number
  product: Product
}

export function calculateCoMax({ order, promotions, currencyOptions, cartItems, customer, currency }: Input) {
  const coRateFormer = R.pipe(
    R.defaultTo([]),
    R.find(R.propEq('name', 'UsePoints')),
    R.defaultTo({ custom5: '900' }),
    R.propOr('900', 'custom5'),
    parseFloat
  )(promotions)

  const { taxExemption: coMultiplier } = customer || { taxExemption: '1' }

  const coRate = coRateFormer / (currency === 'SEK' ? 1 : currencyOptions.rate)

  const getCoPointsAvailable = (obj: TotalsPerItem) => {
    if (!obj.product) return 0

    const percentage = obj.product[PERCENTAGE_OF_POINT_USED]
      ? parseFloat(obj.product[PERCENTAGE_OF_POINT_USED]) * parseFloat(coMultiplier)
      : 0

    return getClubOnePointsAvailable(obj.total, coRate, percentage)
  }

  const totalPerItem = (item: CartItem): TotalsPerItem => {
    return {
      ...item,
      total: item?.product.orderProductPrice,
    }
  }

  const applyDiscountToProductPrice = (order: Order) => {
    return (item: CartItem) => {
      const BUNDLE_CLASS_NAME = 'ot_dfshop_buy_x_get_y_free'
      const bundleQuantity = (
        order.orderTotals.filter(
          ({ className, custom2 }) => className === BUNDLE_CLASS_NAME && custom2 === item?.product?.sku
        ) || []
      ).length

      const priceWithBundle = calculateBundlePrice(bundleQuantity, item.quantity, item.product?.price0 || 0)

      const discountClassNames = ['ot_df_product_discount', 'ot_df_global_product_discount', 'ot_df_total_discount']

      const otTotalDiscount = order.orderTotals.find(({ className }) => className === 'ot_df_total_discount')

      let totalPromotionProductsSku: string[] = []

      if (otTotalDiscount) {
        const applicableProducts = otTotalDiscount.promotions[0].applicableProducts
        if (applicableProducts) {
          totalPromotionProductsSku = applicableProducts.map((item) => item.sku)
        }
      }

      const { discountPercent = 0 } = order.orderTotals.find(
        (ot) =>
          discountClassNames.includes(ot.className) &&
          (ot.custom2 === item?.product?.sku ||
            (otTotalDiscount && totalPromotionProductsSku.includes(item?.product?.sku)))
      ) || {
        value: 0,
      }

      const discountAmount = Math.round(item.finalPriceExTax * discountPercent) / 100
      const discountAmountBundle = Math.round(priceWithBundle * discountPercent) / 100
      const discountedPrice = +(item.finalPriceExTax - discountAmount).toFixed(2)
      const discountedPriceWithBundle = +(priceWithBundle - discountAmountBundle).toFixed(2)
      return {
        ...item,
        product: {
          ...item.product,
          orderProductPrice: !!bundleQuantity ? discountedPriceWithBundle : discountedPrice,
        },
      }
    }
  }

  const app = R.pipe(R.map(applyDiscountToProductPrice(order)), R.map(totalPerItem), R.map(getCoPointsAvailable), R.sum)

  return app(cartItems)
}
